<template>
  <div v-if="props.isEdit && props.modelValue" class="edit-area">
    <div v-if="props.showTitle || props.showButton" class="text-primary text-subtitle1 q-mb-sm" overline>
      <span v-if="props.showTitle">
        <span v-if="required" class="required-field">*</span>
        {{ props.title }}
      </span>
      <span v-if="props.showButton">
        <q-icon
          v-if="props.modelValue[props.property] != props.originData[props.property]"
          class="q-ml-sm cursor-pointer outlined"
          color="primary"
          name="edit"
          size="16px"
          :title="txtTitle"
          @click="reset()" />
      </span>
    </div>
    <slot>
      <span v-if="props.type == 'none'" />
      <q-toggle v-else-if="props.type == 'toggle'" v-model="value" />
      <!-- @vue-ignore -->
      <q-input
        v-else-if="props.type == 'textArea'"
        v-model="value"
        :maxlength="props.maxlength"
        outlined
        :rows="props.rows"
        type="textarea" />
      <!-- @vue-ignore -->
      <mik-input
        v-else
        v-model="value"
        :clearable="true"
        :is-disable="readonly"
        :maxlength="props.maxlength"
        :type="props.type" />
    </slot>
  </div>
  <div v-else style="width: 100%">
    <slot name="origin">
      <template v-if="props.type == 'none'" />
      <template v-else>
        {{ props.originData[props.property] }}
      </template>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { QInput } from 'quasar'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const txtTitle = t('component_cancelEdit', '편집취소')
// Default값 사용시
interface Props {
  isEdit: boolean
  modelValue: any
  originData: any
  property: string
  title?: string
  readonly?: boolean
  type?: 'text' | 'number' | 'textArea' | 'password' | 'toggle' | 'none'
  /** text input, textarea에 적용 */
  maxlength?: string | undefined
  /** textarea에 적용 */
  rows?: string
  /** 수정된 내용을 rollback 하는 버튼 사용 여부 */
  showButton?: boolean
  /** 제목 표시 여부 - 제목을 커스텀으로 사용할 경우 false */
  showTitle?: boolean
  /** 필수 필드 여부 */
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  readonly: false,
  type: 'text',
  maxlength: undefined,
  rows: '4',
  showButton: true,
  showTitle: true
})

const value = computed({
  get() {
    return props.modelValue[props.property]
  },
  set(val) {
    const temp = Object.assign({}, props.modelValue)
    temp[props.property] = val
    emit('update:modelValue', temp)
  }
})

const reset = () => {
  if (props.modelValue && props.originData) {
    const temp = Object.assign({}, props.modelValue)
    temp[props.property] = props.originData[props.property]
    emit('update:modelValue', temp)
  }
}

const emit = defineEmits<{ (e: 'update:modelValue', value: object): void }>()

defineExpose({
  focus
})
const refInput: Ref<InstanceType<typeof QInput> | null> = ref(null)

onMounted(() => {
  if (props.type === 'text') {
    if (refInput.value) {
      refInput.value.$el.querySelector('input').addEventListener('input', function (e: InputEvent) {
        const target = e.target as HTMLInputElement
        value.value = target?.value
      })
    }
  }
})
</script>

<style scoped lang="scss">
.required-field {
  padding-right: 1px;
}

.outlined {
  border: 1px solid;
  border-radius: 50%;
  padding: 5px;
  &:hover {
    background-color: rgb(253, 226, 226);
  }
}
</style>
