<template>
  <div class="q-pa-md" style="max-width: 300px">
    <div class="q-gutter-md">
      <!-- <q-badge color="secondary" multi-line> Model: "{{ value }}" </q-badge> -->
      <!-- @vue-ignore -->
      <q-select
        ref="qSelectRef"
        v-model="selected"
        filled
        dense
        :emit-value="props.emitValue"
        fill-input
        :hide-selected="props.useFilter"
        input-debounce="0"
        :map-options="props.mapOptions"
        :options="props.useFilter ? filteredOptions : props.options"
        outlined
        :use-input="props.useFilter"
        :value="inputValue"
        :disable="props.disable"
        :popup-content-style="resizable()"
        multiple
        @click="checkSelect">
        <template v-if="props.useSelectAll" #before-options>
          <q-item style="padding-top: 0; padding-bottom: 0; width: 100%; height: 34px">
            <q-item-section>
              <q-checkbox v-model="checked" :label="t('SelectAll')" :val="0"></q-checkbox>
            </q-item-section>
          </q-item>
        </template>
        <template #option="scope">
          <q-item v-bind="scope.itemProps" style="width: 100%; height: 100%">
            <q-item-section>
              <q-checkbox
                v-model="value"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding-left: 5%"
                :val="scope.opt[props.valueName]"
                :label="getLabelName(scope.opt)"
                @update:model-value="updateModelValue">
              </q-checkbox>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

interface Props {
  modelValue: any
  /** 전체선택 버튼 사용 여부 */
  useSelectAll?: boolean
  options?: any[]
  /** 전체선택 여부: v-model */
  selectAll?: boolean
  /** 검색 필터 사용 여부 */
  useFilter?: boolean
  /** 필터링 할 프로퍼티명 */
  filterProperty?: string
  /** options 중 첫번째 요소를 자동 선택할지 여부 */
  selectFirst?: boolean
  /** 전체 옵션 대신 선택한 옵션의 값으로 모델 업데이트 */
  emitValue?: boolean
  mapOptions?: boolean
  /** Select DropDown 최대 표시 수 */
  maxItemCount?: number
  /** 비활성화 여부 */
  disable?: boolean
  labelName?: string | ((option: any) => string)
  valueName?: string
  checkValue?: string[]
  showOptionsCountLimit?: boolean
  showOptionsCount?: number
}

const props = withDefaults(defineProps<Props>(), {
  useSelectAll: false,
  selectAll: false,
  options: () => [],
  useFilter: false,
  filterProperty: '',
  selectFirst: true,
  emitValue: true,
  mapOptions: true,
  maxItemCount: 5,
  disable: false,
  labelName: 'label',
  valueName: 'value',
  showOptionsCountLimit: false,
  showOptionsCount: 5
})

const qSelectRef = ref()
const inputValue = ref('')
const filteredOptions = ref([])
const value: Ref<any[]> = ref([])
const selected = ref()
const { t } = useI18n()

const resizable = () => {
  let list: any[] = new Array()
  list = props.useFilter ? filteredOptions.value : props.options
  if (props.showOptionsCountLimit && list.length + 1 <= props.showOptionsCount) {
    return 'height: ' + (list.length + 1) * 33 + 'px;'
  } else if (props.showOptionsCountLimit && list.length + 1 > props.showOptionsCount) {
    return 'height: ' + props.showOptionsCount * 33 + 'px;'
  } else {
    if (list.length <= props.maxItemCount) {
      return 'height: ' + list.length * 42 + 'px;'
    } else {
      return 'height: ' + props.maxItemCount * 42 + 'px;'
    }
  }
}

/** 전체선택 */
const checked = computed({
  get() {
    return props.selectAll
  },
  set(checked: boolean) {
    value.value = []
    selected.value = []
    if (checked && props.options.length > 0) {
      for (const option of props.options) {
        if (option[props.valueName]) {
          value.value.push(option[props.valueName])
        }
      }
      selected.value = t('SelectAll')
    }
    emit('update:model-value', value.value)
  }
})

const updateModelValue = (events: any[]) => {
  selected.value = []
  if (props.options.length === events.length) {
    checked.value = true
  } else {
    checked.value = false
    events.forEach((event) => {
      props.options.forEach((option) => {
        if (option[props.valueName] === event) {
          selected.value.push(getLabelName(option))
          value.value.push(option[props.valueName] ?? 0)
          return false
        }
      })
    })
    emit('update:model-value', events)
  }
}

const getLabelName = (option: any) => {
  if(typeof(props.labelName) === 'string'){
    return option[props.labelName]
  }
  else{
    return props.labelName(option)
  }
}

watch(
  () => props.options,
  (newVal) => {
    filteredOptions.value = Object.assign([], newVal)
    if (props.useSelectAll) {
      checked.value = true
    }
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: any): void
}>()

onMounted(() => {
  if (props.useFilter && props.filterProperty) {
    qSelectRef.value.$el.querySelector('input').addEventListener('input', function (e: InputEvent) {
      const target = e.target as HTMLInputElement
      inputValue.value = target.value

      if (!inputValue.value) {
        filteredOptions.value = Object.assign([], props.options)
      } else {
        const temp = props.options.filter((v) => {
          return v[props.filterProperty].toLocaleLowerCase().indexOf(inputValue.value) > -1
        })
        filteredOptions.value = Object.assign([], temp)
      }
    })
  }
  if (props.selectAll) {
    checked.value = true
  }
})
</script>
