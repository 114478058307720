<template>
  <q-select v-model="selected" dense :options="dropDownOptions" :label="label" :readonly="readonly" />
</template>

<script setup lang="ts">
export interface DropDownOption {
  label: string
  value: string
  disable?: boolean
  // 기능 이상으로 제거
  // isSelected?: boolean
  description?: string
  category?: string
}

export interface Props {
  dropDownOptions?: DropDownOption[]
  modelValue?: DropDownOption
  label?: string
  readonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  dropDownOptions: undefined,
  modelValue: undefined,
  label: undefined,
  readonly: false
})

const selected = computed({
  get(): DropDownOption {
    if (props.modelValue) {
      //console.log(`Dropdown value: ${props.modelValue}`)
      // const obj = props.dropDownOptions?.find((value, index, obj) => (value as DropDownOption).isSelected == true)
      // if (obj) {
      //   return obj
      // } else {
      return props.modelValue
      //}
    } else {
      //console.log(`Dropdown value: null`)
      return { label: '', value: '' } as DropDownOption
    }
  },
  set(val: DropDownOption) {
    //const obj = props.dropDownOptions.find((value, index, obj) => (value as DropDownOption).value == val) as DropDownOption;
    emit('update:modelValue', val)
  }
})

const emit = defineEmits<{ (e: 'update:modelValue', value: DropDownOption): void }>()
</script>
