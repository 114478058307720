import moment from 'moment'
import { RouteLocationNormalized } from 'vue-router'

export const useModeStore = defineStore('pageMode', {
  state: (): IMode => ({
    // 공백페이지
    isEmpty: false,
    // 팝업여부
    isPopup: false,
    // 인쇄모드 여부
    isPrint: false,
    // 로컬메뉴 사용여부
    useLocalMenu: true,
    useGnbMenu: true,
    // Local메뉴 표시 조건
    alternativeUrl: '',
    // Footer 사용여부
    showFooter: false,
    ajaxFilterList: []
  }),
  getters: {
    isPopupMode(state): boolean {
      return state.isPopup
    },
    isPrintMode(state): boolean {
      return state.isPrint
    },
    isEmptyMode(state): boolean {
      return state.isEmpty
    },
    useLocalMenuMode(state): boolean {
      return state.useLocalMenu
    },
    getAlternativeUrl(state): string {
      return state.alternativeUrl
    },
    showFooterMode(state): boolean {
      return state.showFooter
    },
    getAjaxFilterList(state): string[] {
      return state.ajaxFilterList
    },
    useGnbMenuMode(state): boolean {
      return state.useGnbMenu
    }
  },
  actions: {
    setPopupAndPrintMode(isPopup: boolean, isPrint: boolean) {
      this.isPopup = isPopup
      this.isPrint = isPrint
    },
    setEmptyMode(isEmpty: boolean) {
      this.isEmpty = isEmpty
    },
    setLocalMenuMode(useLocalMenu: boolean) {
      this.useLocalMenu = useLocalMenu
    },
    setGnbMenuMode(useGnbMenu: boolean) {
      this.useGnbMenu = useGnbMenu
    },
    setMode(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      const popclsid: string | undefined = (to.query.POPCLSID ?? to.query.popclsid)?.toString()
      const ipm: string | undefined = (to.query.IPM ?? to.query.ipm)?.toString()
      const useLocalMenu: boolean = (to.meta.useLocalMenu as boolean) ?? false

      this.isEmpty = (to.meta.isEmpty as boolean) ?? false
      this.isPopup = popclsid ? true : false
      this.isPrint = ipm && ipm.toUpperCase() === 'Y' ? true : false
      this.useLocalMenu = useLocalMenu

      this.setAlternativeUrl(to, from)

      //if (this.isPopupMode) {
      this.showFooter = false
      //}
    },
    setAlternativeUrl(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      // boardModulePath : 일반적으로 /Portal
      const boardModulePath = import.meta.env.V_PORTAL_PATH
      switch (to.name) {
        // 게시판일 경우 편집/조회/목록에서 같은 LocalMenu를 보여주기 위한 처리
        case 'BoardEdit':
        case 'BoardView':
        case 'BoardList': {
          const boardName = to.params.boardName as string
          this.alternativeUrl = `${location.protocol}//${location.host}${boardModulePath}/Board/${boardName}`
          break
        }
        case 'GalleryEdit':
        case 'GalleryView':
        case 'GalleryList': {
          const boardName = to.params.boardName as string
          this.alternativeUrl = `${location.protocol}//${location.host}${boardModulePath}/Gallery/${boardName}`
          break
        }
        case 'StaticView': {
          const siteStaticName = to.params.siteStaticName as string
          const sectionStaticName = to.params.sectionStaticName as string
          this.alternativeUrl = `${location.protocol}//${location.host}${boardModulePath}/Static/View/${siteStaticName}/${sectionStaticName}`
          break
        }
        // 동의서
        case 'ManagementList':
          this.alternativeUrl = `${location.protocol}//${location.host}/ConsentForm/Management/List`
          break
        case 'ManagementView':
          this.alternativeUrl = `${location.protocol}//${location.host}/ConsentForm/Management/View`
          break
        case 'ManagementStatusList':
          this.alternativeUrl = `${location.protocol}//${location.host}/ConsentForm/Management/Status/List`
          break
        case 'ManagementStatusView':
          this.alternativeUrl = `${location.protocol}//${location.host}/ConsentForm/Management/Status/View`
          break
        case 'ManagementStatusStatistics':
          this.alternativeUrl = `${location.protocol}//${location.host}/ConsentForm/Management/Status/Statistics`
          break
        case 'meetingRoom':
        case 'meetingRoomReservation':
        case 'meetingRoomReservationList':
          this.alternativeUrl = `${location.protocol}//${location.host}/Reservation/user/meetingRoom`
          break
        case 'meetingRoomReservationAvailable':
          this.alternativeUrl = `${location.protocol}//${location.host}/Reservation/user/meetingRoom/reservationAvailable`
          break
        case 'meetingRoomReservationDetail':
          if (from.name == 'meetingRoomReservationAvailable') {
            this.alternativeUrl = `${location.protocol}//${location.host}/Reservation/user/meetingRoom/reservationAvailable`
          } else {
            this.alternativeUrl = `${location.protocol}//${location.host}/Reservation/user/meetingRoom`
          }
          break
        case 'equipment':
        case 'equipmentReservationDetail':
        case 'equipmentReservationList':
        case 'equipmentReservation':
          this.alternativeUrl = `${location.protocol}//${location.host}/Reservation/user/equipment`
          break
        default:
          if (typeof to.meta.getBaseUrl === 'function') {
            this.alternativeUrl = `${to.meta?.getBaseUrl(to)}?ticks=${moment().unix()}`
          } else {
            this.alternativeUrl = `${location.protocol}//${location.host}${to.fullPath}`
          }
          break
      }
    },
    setAjaxFilterList(url: string) {
      const index = this.ajaxFilterList.findIndex((item) => item.toLocaleLowerCase() == url.toLowerCase())
      if (index === -1) {
        this.ajaxFilterList.push(url)
      }
    },
    removeAjaxFilterList(url: string) {
      const index = this.ajaxFilterList.findIndex((item) => item.toLocaleLowerCase() == url.toLowerCase())
      if (index === -1) {
        return false
      } else {
        this.ajaxFilterList.splice(index, 1)
        return true
      }
    },
    isExistsAjaxFilterList(url: string) {
      const index = this.ajaxFilterList.findIndex((item) => item.toLocaleLowerCase() == url.toLowerCase())
      if (index === -1) {
        return false
      } else {
        return true
      }
    }
  }
})

export interface IMode {
  isEmpty: boolean
  isPopup: boolean
  isPrint: boolean
  useLocalMenu: boolean
  useGnbMenu: boolean
  alternativeUrl: string
  showFooter: boolean
  ajaxFilterList: string[]
}
