<template>
  <div class="mik-calendar-container">
    <div v-if="props.enablePeriodSelector && props.enablePeriod && props.mode === 'date'" class="period-selector">
      <mik-select v-model="period" :options="searchPeriodModel" />
    </div>
    <div v-if="props.mode === 'date' || props.mode === 'dateTime'">
      <!-- @vue-ignore -->
      <mik-input
        v-model="from"
        dense
        :mask="inputMask"
        outlined
        :disable="isDisable || (enablePeriodSelector && period !== 'Specified') || props.fromDisabled"
        style="width: 150px"
        @click="clickInput(true)">
        <template #append>
          <q-icon class="cursor-pointer" name="event">
            <!-- @vue-ignore -->
            <q-popup-proxy
              v-model="showFromPopup"
              :offset="[width - 55, 10]"
              transition-hide="scale"
              transition-show="scale">
              <div class="q-gutter-md row items-start">
                <!-- @vue-ignore -->
                <q-date
                  v-model="from"
                  :disable="isDisable || props.fromDisabled"
                  :mask="mask"
                  minimal
                  no-unset
                  :options="options"
                  :range="false"
                  :readonly="isReadOnly || props.fromDisabled"
                  today-btn
                  :locale="myLocale[langCode]"
                  @update:model-value="hidePopup(true)">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup color="primary" flat label="Close" />
                  </div>
                </q-date>
              </div>
            </q-popup-proxy>
          </q-icon>
        </template>
      </mik-input>
    </div>
    <div v-if="props.mode === 'time' || props.mode === 'dateTime'">
      <!-- @vue-ignore -->
      <mik-input
        v-model="fromTime"
        dense
        mask="time"
        outlined
        :disable="isDisable || props.fromDisabled"
        :readonly="isReadOnly"
        style="width: 125px">
        <template #append>
          <q-btn
            class="q-pa-none"
            flat
            icon="schedule"
            :outline="false"
            size="s"
            :disable="isDisable || props.fromDisabled">
            <q-menu v-model="showFromTimeMenu" anchor="top right" class="time-picker-container" :offset="[10, 0]">
              <q-btn-toggle v-model="fromTime" v-close-popup :options="timeOptions" />
            </q-menu>
          </q-btn>
        </template>
      </mik-input>
    </div>
    <div v-if="enablePeriod" class="row items-center justify-center" style="width: 25px">~</div>
    <div v-if="enablePeriod && (props.mode === 'date' || props.mode === 'dateTime')">
      <!-- @vue-ignore -->
      <mik-input
        v-model="to"
        dense
        :mask="inputMask"
        :disable="isDisable || (enablePeriodSelector && period !== 'Specified') || props.toDisabled"
        outlined
        :readonly="isReadOnly"
        style="width: 150px"
        @click="clickInput(false)">
        <template #append>
          <q-icon class="cursor-pointer" name="event">
            <!-- @vue-ignore -->
            <q-popup-proxy
              v-model="showToPopup"
              :offset="[width - 55, 10]"
              transition-hide="scale"
              transition-show="scale">
              <div class="q-gutter-md row items-start">
                <!-- @vue-ignore -->
                <q-date
                  v-model="to"
                  :disable="isDisable || props.toDisabled"
                  :mask="mask"
                  minimal
                  no-unset
                  :options="options"
                  :range="false"
                  :readonly="isReadOnly || props.toDisabled"
                  today-btn
                  @update:model-value="hidePopup(false)">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup color="primary" flat label="Close" />
                  </div>
                </q-date>
              </div>
            </q-popup-proxy>
          </q-icon>
        </template>
      </mik-input>
    </div>
    <div v-if="enablePeriod && (props.mode === 'time' || props.mode === 'dateTime')" class="q-pl-sm">
      <!-- @vue-ignore -->
      <mik-input
        v-model="toTime"
        dense
        mask="time"
        outlined
        :readonly="isReadOnly || props.toDisabled"
        :disable="isDisable || props.toDisabled"
        style="width: 125px">
        <template #append>
          <q-btn
            class="q-pa-none"
            flat
            icon="schedule"
            :outline="false"
            size="s"
            :disable="isDisable || props.toDisabled">
            <q-menu v-model="showToTimeMenu" anchor="top right" class="time-picker-container" :offset="[10, 0]">
              <q-btn-toggle v-model="toTime" v-close-popup :options="timeOptions" />
            </q-menu>
          </q-btn>
        </template>
      </mik-input>
    </div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import { useToast } from '../../store/useToast'
import { formatDate, isValidFormat } from '../../common/common'
import moment, { Moment } from 'moment'
import { Cookies } from 'quasar'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
export interface DatePickerInfo {
  from?: string
  to?: string
  fromTime?: string
  toTime?: string
}

interface Props {
  modelValue: Moment | string | DatePickerInfo
  mode?: 'date' | 'time' | 'dateTime'
  isReadOnly?: boolean
  isDisable?: boolean
  label?: string
  enablePeriodSelector?: boolean
  enablePeriod?: boolean
  disablePrevTargetDate?: boolean /** 특정 날짜 이전 선택 불가하게 하는 옵션 */
  targetDate?: string | null /** disablePrevTargetDate 와 함께 사용할 특정 날짜 - 기본:오늘 */
  // enablePeriod 사용 시 아래 minDate, maxDate와 호환이 되지 않음.
  minDate?: string
  maxDate?: string
  width?: number
  startHour?: number
  endHour?: number
  timeTerm?: 15 | 30 | 60
  showToTimeMenu?: boolean
  showFromTimeMenu?: boolean
  useValidation?: boolean /** validation 체크여부 설정 옵션 */
  fromDisabled?: boolean
  toDisabled?: boolean
  lastTimeSet?: boolean
  lastTimeSetMidnight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
  mode: 'date',
  isDisable: false,
  label: '',
  enablePeriod: false,
  enablePeriodSelector: false,
  disablePrevTargetDate: false,
  targetDate: formatDate(moment()),
  minDate: '1900-01-01',
  maxDate: '2999-01-01',
  width: 300,
  startHour: 0,
  endHour: 24,
  timeTerm: 30,
  fromTimeMenuModel: false,
  toTimeMenuModel: false,
  useValidation: false,
  fromDisabled: false,
  toDisabled: false,
  lastTimeSet: false,
  lastTimeSetMidnight: false
})

const showFromTimeMenu = computed({
  get() {
    return props.fromTimeMenuModel
  },
  set(value: boolean) {
    emit('update:fromTimeMenuModel', value)
  }
})
const showToTimeMenu = computed({
  get() {
    return props.toTimeMenuModel
  },
  set(value: boolean) {
    emit('update:toTimeMenuModel', value)
  }
})

const toast = useToast()

const showFromPopup = ref(false)
const showToPopup = ref(false)
const hidePopup = (isFrom: boolean) => {
  if (isFrom && from.value) {
    showFromPopup.value = false
  } else if (!isFrom && to.value) {
    showToPopup.value = false
  }
}

const period = ref('AMonth')

// 검색조건
const searchPeriodModel = [
  {
    label: t('CommonADay', '하루'),
    value: 'ADay'
  },
  {
    label: t('CommonAWeek', '한 주'),
    value: 'AWeek'
  },
  {
    label: t('CommonAMonth', '한 달'),
    value: 'AMonth'
  },
  {
    label: t('CommonThreeMonth', '세 달'),
    value: 'ThreeMonths'
  },
  {
    label: t('CommonHalfYear', '반년'),
    value: 'HalfYear'
  },
  {
    label: t('CommonAYear', '1년'),
    value: 'AYear'
  },
  {
    label: t('CommonSpecified', '직접지정'),
    value: 'Specified'
  }
]

const from = computed({
  get() {
    let value = props.modelValue
    if (typeof props.modelValue === 'string') {
      value = moment(props.modelValue)
    } else if ('from' in props.modelValue) {
      const modelObject = Object.assign({}, props.modelValue) as DatePickerInfo
      value = moment(modelObject.from)
    } else {
      value = props.modelValue as string
    }
    return formatDate(value)
  },
  set(newFrom) {
    let newTo = isValidFormat(to.value) ? moment(to.value) : ''
    if (props.useValidation && props.enablePeriod && newFrom && newTo) {
      if (moment(newFrom) > newTo) {
        newTo = moment(newFrom).endOf('day')
        toast.error(t('CalenderValidate1', '시작일이 종료일 이후일 수 없습니다.'))
        return false
      }
    }

    if (props.mode === 'date') {
      if (!props.enablePeriod) {
        emit('update:modelValue', formatDate(newFrom))
      } else {
        emit('update:modelValue', {
          from: formatDate(newFrom),
          to: formatDate(newTo)
        })
      }
    } else if (props.mode === 'dateTime') {
      emit('update:modelValue', {
        from: formatDate(newFrom),
        to: formatDate(newTo),
        fromTime: (fromTime.value as string) ?? undefined,
        toTime: (toTime.value as string) ?? undefined
      })
    } else {
      return false
    }
  }
})

const to = computed({
  get() {
    let value = props.modelValue
    if (typeof props.modelValue !== 'string' && 'to' in props.modelValue) {
      const modelObject = Object.assign({}, props.modelValue) as DatePickerInfo
      value = moment(modelObject.to)
    } else {
      value = props.modelValue as string
    }
    return formatDate(value)
  },
  set(newTo) {
    // end가 있으면 is-range가 false일 수 없음.
    if (!props.enablePeriod) {
      return false
    }

    // validation check
    if (props.useValidation && isValidFormat(from.value) && from.value > newTo) {
      const newFrom = moment(newTo).startOf('day')
      toast.error(t('CalenderValidate2', '종료일이 시작일 이전일 수 없습니다.'))
      return false
    }

    emit('update:modelValue', {
      from: formatDate(from.value),
      to: formatDate(newTo),
      fromTime: (fromTime.value as string) ?? undefined,
      toTime: (toTime.value as string) ?? undefined
    })
  }
})

const fromTime: WritableComputedRef<string> = computed({
  get() {
    if (props.mode === 'date') {
      return ''
    }

    if (typeof props.modelValue !== 'string' && 'fromTime' in props.modelValue) {
      const modelObject = Object.assign({}, props.modelValue) as DatePickerInfo
      return modelObject.fromTime ?? ('' as string)
    } else {
      return props.modelValue as string
    }
  },
  set(newValue: string) {
    // validation check
    if (props.useValidation) {
      if (props.enablePeriod) {
        // 날짜 선택이 되어있지 않은 경우 시간만 비교한다.
        if (!from.value && !to.value) {
          if (newValue > toTime.value) {
            toast.error(t('CalenderValidate1', '시작일이 종료일 이후일 수 없습니다.'))
            return false
          }
        } else {
          // 날짜 선택이 되어 있는 경우 toTime 선택이 되어있어야 validation 체크를 한다.
          if (toTime.value) {
            const start = moment(`${from.value} ${newValue}`)
            const end = moment(`${to.value} ${toTime.value}`)
            if (start > end) {
              toast.error(t('CalenderValidate1', '시작일이 종료일 이후일 수 없습니다.'))
              return false
            }
          }
        }
      }
    }

    if (props.enablePeriod) {
      emit('update:modelValue', {
        from: (from.value as string) ?? undefined,
        to: (to.value as string) ?? undefined,
        fromTime: newValue,
        toTime: toTime.value as string
      })
    } else if (props.mode === 'time') {
      emit('update:modelValue', newValue)
    } else if (props.mode === 'dateTime') {
      emit('update:modelValue', {
        from: (from.value as string) ?? undefined,
        to: (to.value as string) ?? undefined,
        fromTime: newValue,
        toTime: toTime.value as string
      })
    } else {
      return false
    }
  }
})

const toTime: WritableComputedRef<string> = computed({
  get() {
    if (props.mode === 'date' || !props.enablePeriod) {
      return ''
    }

    if (typeof props.modelValue !== 'string' && 'toTime' in props.modelValue) {
      const modelObject = Object.assign({}, props.modelValue) as DatePickerInfo
      return modelObject.toTime ?? ('' as string)
    } else {
      return props.modelValue as string
    }
  },
  set(newValue: string) {
    // end가 있으면 is-range가 false일 수 없음.
    if (!props.enablePeriod) {
      return false
    }

    // validation check
    // 날짜 선택이 되어있지 않은 경우 시간만 비교한다.
    if (props.useValidation) {
      if (!from.value && !to.value) {
        if (from.value > newValue) {
          toast.error(t('CalenderValidate1', '시작일이 종료일 이후일 수 없습니다.'))
          return false
        }
      } else {
        // 날짜 선택이 되어 있는 경우 fromTime 선택이 되어있어야 validation 체크를 한다.
        if (fromTime.value) {
          const start = moment(`${from.value} ${fromTime.value}`)
          const end = moment(`${to.value} ${newValue}`)
          if (start > end) {
            toast.error(t('CalenderValidate1', '시작일이 종료일 이후일 수 없습니다.'))
            return false
          }
        }
      }
    }

    emit('update:modelValue', {
      from: (from.value as string) ?? undefined,
      to: (to.value as string) ?? undefined,
      fromTime: fromTime.value as string,
      toTime: newValue
    })
  }
})

const length = computed(() => {
  return (props.endHour - props.startHour) * (60 / props.timeTerm) + 1
})

/** 시간 목록 */
const timeOptions = Array.from(
  {
    length: length.value
  },
  (_, hour) => {
    if (props.lastTimeSetMidnight && hour === 48) {
      return {
        label: '24:00',
        value: '24:00'
      }
    } else if (props.lastTimeSet && hour === 48) {
      return {
        label: moment({
          hour: 23,
          minutes: 59
        }).format('HH:mm'),
        value: moment({
          hour: 23,
          minutes: 59
        }).format('HH:mm')
      }
    } else {
      return {
        label: moment({
          hour: Math.floor(hour / 2) + props.startHour,
          minutes: hour % 2 === 0 ? 0 : 30
        }).format('HH:mm'),
        value: moment({
          hour: Math.floor(hour / 2) + props.startHour,
          minutes: hour % 2 === 0 ? 0 : 30
        }).format('HH:mm')
      }
    }
  }
)

const clickInput = (isFrom: boolean) => {
  if (isFrom) {
    showFromPopup.value = true
  } else {
    showToPopup.value = true
  }
}

/** minDate, maxDate 사이에서 날짜 선택 */
const options = (date: string): boolean => {
  const flag1 = optionA(date)
  const flag2 = optionB(date)
  return flag1 && flag2
}

/** minDate, maxDate 사이에서 날짜 선택 */
const optionA = (date: string): boolean => {
  return date >= props.minDate && date <= props.maxDate
}
/** 특정 날짜 이전 선택 불가 */
const optionB = (date: string): boolean => {
  if (date.indexOf('/') > 0) {
    date = date.replaceAll('/', '-')
  }
  if (!props.disablePrevTargetDate) {
    return true
  } else if (props.disablePrevTargetDate && props.targetDate) {
    return formatDate(date) >= formatDate(props.targetDate)
  }
  return true
}

const inputMask = computed((): string => {
  return `####-##-##`
})

const mask = computed((): string => {
  return `YYYY-MM-DD`
})

watch(
  () => period.value,
  (newVal) => {
    if (newVal !== 'Specified') {
      let cnt = 0
      let calendar: 'days' | 'months' | 'years' = 'days'
      switch (newVal) {
        case 'ADay':
          cnt = 1
          calendar = 'days'
          break
        case 'AWeek':
          cnt = 7
          calendar = 'days'
          break
        case 'AMonth':
          cnt = 1
          calendar = 'months'
          break
        case 'ThreeMonths':
          cnt = 3
          calendar = 'months'
          break
        case 'HalfYear':
          cnt = 6
          calendar = 'months'
          break
        case 'AYear':
          cnt = 1
          calendar = 'years'
          break
      }

      const newFrom = formatDate(moment().subtract(cnt, calendar).add(1, 'days').hours(0).minutes(0).seconds(0))
      const newTo = formatDate(moment().hours(23).minutes(59).seconds(59))

      emit('update:modelValue', { from: newFrom, to: newTo })

      // Specified가 아닌 경우 바로 검색 실행 - 추후 필요하면 옵션화하여 바로 함수 실행
      // refresh()
    }
  }
)
const langCode = Cookies.get('GWP_LANGUAGE_CODE')
const myLocale = {
  ko: {
    days: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split(''),
    daysShort: '일_월_화_수_목_금_토'.split('_'),
    months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  },
  en: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(''),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  },
  zh: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(''),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  }
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: Moment | string | DatePickerInfo): void
  (e: 'update:fromTimeMenuModel', value: boolean): void
  (e: 'update:toTimeMenuModel', value: boolean): void
}>()
</script>
<style lang="scss">
.mik-calendar-container {
  display: flex;

  .period-selector {
    margin-right: 10px;
    min-width: 120px;
  }
}
.time-picker-container {
  display: flex;

  .q-btn-group {
    width: 280px;
    flex-wrap: wrap;
    box-shadow: none;

    .q-btn-item {
      border-radius: 5px;
      width: 70px;
    }
  }
}
</style>
