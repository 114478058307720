<template>
  <q-dialog v-if="props.modelValue" v-model="vModel" :persistent="props.persistent" @update:model-value="close">
    <q-card class="pop-container" :class="`pop-container-${props.size}`">
      <q-card-section class="pop-header">
        <div class="row">
          <div class="col text-h6 self-start">
            {{ props.title }}
          </div>
          <div class="justify-center cursor-pointer q-mr-ms">
            <q-icon name="close" size="md" @click="handleCancelClick" />
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section class="pop-contents">
        <div style="flex: 1">
          <slot />
        </div>
      </q-card-section>
      <slot v-if="useButton" name="button">
        <q-card-actions class="row justify-center">
          <mik-button icon="done" :label="props.okText" @click="handleOkClick" />
          <mik-button color="secondary" icon="close" :label="props.cancelText" @click="handleCancelClick" />
          <!-- 삭제 -->
          <mik-button v-if="useDelButton" icon="delete" label="삭제" type="secondary" @click="handleDeleteClick" />
        </q-card-actions>
      </slot>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
export interface Props {
  modelValue?: boolean
  persistent?: boolean
  title?: string
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl' | 'fullscreen'
  useButton?: boolean
  okText?: string
  cancelText?: string
  useDelButton?: boolean
  onOk?: ((close: () => void) => void) | null
  onCancel?: (() => void) | null
  onDel?: ((close: () => void) => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  persistent: true,
  title: 'Smart Portal',
  size: 'm',
  useButton: false,
  okText: '확인',
  cancelText: '취소',
  useDelButton: false,
  onOk: null,
  onCancel: null,
  onDel: null
})

const vModel = computed<boolean>(() => props.modelValue)

const handleOkClick = () => {
  if (props.onOk) {
    props.onOk(close)
  } else {
    close()
  }
}

const handleCancelClick = () => {
  if (props.onCancel) {
    props.onCancel()
  }
  close()
}

const handleDeleteClick = () => {
  if (props.onDel) {
    props.onDel(close)
  }
  close()
}

const close = () => {
  emit('update:modelValue', false)
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'update:use-alert', value: boolean): void
  (e: 'update:use-info', value: boolean): void
  (e: 'update:use-confirm', value: boolean): void
}>()
</script>

<style scoped lang="scss">
.pop-container {
  overflow: hidden;

  .pop-header {
    background-color: #272727;
    color: #fff;
    font-size: 16px !important;
  }
  .pop-contents {
    overflow-y: auto;
    max-width: unset;
    max-height: 100%; // Header, Button
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-s {
    width: 480px;
    max-width: 90% !important;
  }

  &-m {
    width: 680px;
    max-width: 90% !important;
  }

  &-l {
    width: 880px;
    max-width: 90% !important;
  }

  &-xl {
    width: 1080px;
    max-width: 90% !important;
  }

  &-xxl {
    width: 90%;
    max-width: 90% !important;
    overflow-y: hidden;
    height: calc(100% - 64px);

    .popup {
      height: 80vh;
    }
  }

  &-fullscreen {
    width: 100%;
    max-height: 100vh;

    .pop-contents {
      max-height: 100% !important;
    }
  }
}
</style>
