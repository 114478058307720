/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const NicknameApprovalStatus = {
    Lock: 16,
    Reject: 256,
    Request: 4096,
    Approval: 65536,
    All: 69904
} as const;

export type NicknameApprovalStatus = typeof NicknameApprovalStatus[keyof typeof NicknameApprovalStatus];



