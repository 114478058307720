import { createI18n } from 'vue-i18n'
import { Cookies } from 'quasar'

const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

const instance = createI18n({
  legacy: false,
  globalInjection: true,
  locale: langCode,
  fallbackLocale: langCode
})

export default instance

export const i18n = instance.global
