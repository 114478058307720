import moment, { Moment } from 'moment'
// @ts-ignore
import CommonAlert from '../components/popup/CommonAlert.vue'
// @ts-ignore
import CommonInfo from '../components/popup/CommonInfo.vue'
// @ts-ignore
import CommonConfirm from '../components/popup/CommonConfirm.vue'
import { Dialog, Loading } from 'quasar'

export const Guid = {
  v4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
  empty() {
    return '00000000-0000-0000-0000-000000000000'
  }
}

/**
 * 날짜 및 시간 포맷
 */
type DateType = string | Moment | Date | null | undefined

export const isValidFormat = (value: DateType) => {
  if (!value || value === 'Invalid date') {
    return false
  } else {
    return true
  }
}

export const formatDateTime = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDateTimeShort = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('YY-MM-DD HH:mm')
}

export const formatDateTimeMilliseconds = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss.SSS')
}

export const formatDate = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD')
}

export const formatTime = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('HH:mm:ss')
}

export const formatTimeHHmm = (dateTime: DateType) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('HH:mm')
}

export const formatDateMonth = (dateTime: string | Moment | Date | undefined) => {
  if (!isValidFormat(dateTime)) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM')
}

/**
 * 날짜와 시간을 합쳐 리턴한다.
 */
export const concatDateTime = (date: string, time: string) => {
  return `${date}T${time}`
}

/**
 * 알림 팝업 호출
 */
export const mikCommonPopup = {
  alert: (message: string) => {
    Dialog.create({
      component: CommonAlert,
      componentProps: {
        message
      }
    })
  },
  info: (message: string) => {
    Dialog.create({
      component: CommonInfo,
      componentProps: {
        message
      }
    })
  },
  confirm: (message: string, okCallback: any | null, params?: any | any[] | null | undefined) => {
    Dialog.create({
      component: CommonConfirm,
      componentProps: {
        message,
        okCallback,
        params
      }
    })
  }
}

/**
 * Loading mask
 */
export const toggleLoadingMask = (isShow: boolean) => {
  if (isShow) {
    Loading.show()
  } else {
    Loading.hide()
  }
}

/** bitwise flag 비교 */
export const hasFlag = <T1 extends number, T2 extends T1>(value: T1 | undefined, flag: T2): boolean => {
  return ((value ?? 0) & flag) > 0
}