/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { NicknameHistoryModelApiResult } from '../models';
// @ts-ignore
import type { NicknameInfoModelApiResult } from '../models';
// @ts-ignore
import type { NicknameLockModel } from '../models';
// @ts-ignore
import type { NicknameResponseModelApiResult } from '../models';
// @ts-ignore
import type { NicknameResultModelApiResult } from '../models';
/**
 * NicknameApi - axios parameter creator
 * @export
 */
export const NicknameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 닉네임 잠금/승인/반려
         * @param {number} [indexID] 
         * @param {number} [approvalStatus] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameChangeStatusUpdateGet: async (indexID?: number, approvalStatus?: number, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/change/status/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (indexID !== undefined) {
                localVarQueryParameter['indexID'] = indexID;
            }

            if (approvalStatus !== undefined) {
                localVarQueryParameter['approvalStatus'] = approvalStatus;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryInfoGet: async (nickName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/history/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickName !== undefined) {
                localVarQueryParameter['nickName'] = nickName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 정지
         * @param {NicknameLockModel} [nicknameLockModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryLockPost: async (nicknameLockModel?: NicknameLockModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/history/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nicknameLockModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임의 게시물 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoBoardListGet: async (nickName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/history/myinfo/board/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickName !== undefined) {
                localVarQueryParameter['nickName'] = nickName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임의 Comment 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoCommentListGet: async (nickName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/history/myinfo/comment/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickName !== undefined) {
                localVarQueryParameter['nickName'] = nickName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue 이력탭에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoHistoryGet: async (nickName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/history/myinfo/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickName !== undefined) {
                localVarQueryParameter['nickName'] = nickName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (lock, change.vue 사용)
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockInfoGet: async (indexID?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/lock/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (indexID !== undefined) {
                localVarQueryParameter['indexID'] = indexID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary nickname unlock 성공은 true, 실패는 fasle, 오류는 success false
         * @param {string} [nickname] 
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockListUnlockGet: async (nickname?: string, indexID?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/lock/list/unlock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }

            if (indexID !== undefined) {
                localVarQueryParameter['indexID'] = indexID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Nickname Memo 업데이트
         * @param {number} [indexID] 
         * @param {string} [memo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockMemoAddGet: async (indexID?: number, memo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/lock/memo/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (indexID !== undefined) {
                localVarQueryParameter['indexID'] = indexID;
            }

            if (memo !== undefined) {
                localVarQueryParameter['memo'] = memo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 사용 가능여부 체크
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameNicknameCheckGet: async (nickname?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/nickname/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 닉네임 설정 (신규/수정)
         * @param {string} [nickname] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameNicknameSetGet: async (nickname?: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nickname/nickname/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NicknameApi - functional programming interface
 * @export
 */
export const NicknameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NicknameApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 닉네임 잠금/승인/반려
         * @param {number} [indexID] 
         * @param {number} [approvalStatus] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameChangeStatusUpdateGet(indexID?: number, approvalStatus?: number, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameChangeStatusUpdateGet(indexID, approvalStatus, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameHistoryInfoGet(nickName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResultModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameHistoryInfoGet(nickName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 정지
         * @param {NicknameLockModel} [nicknameLockModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameHistoryLockPost(nicknameLockModel?: NicknameLockModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameHistoryLockPost(nicknameLockModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임의 게시물 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameHistoryMyinfoBoardListGet(nickName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResultModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameHistoryMyinfoBoardListGet(nickName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임의 Comment 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameHistoryMyinfoCommentListGet(nickName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResultModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameHistoryMyinfoCommentListGet(nickName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue 이력탭에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameHistoryMyinfoHistoryGet(nickName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResultModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameHistoryMyinfoHistoryGet(nickName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (lock, change.vue 사용)
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameLockInfoGet(indexID?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameHistoryModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameLockInfoGet(indexID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary nickname unlock 성공은 true, 실패는 fasle, 오류는 success false
         * @param {string} [nickname] 
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameLockListUnlockGet(nickname?: string, indexID?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameLockListUnlockGet(nickname, indexID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Nickname Memo 업데이트
         * @param {number} [indexID] 
         * @param {string} [memo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameLockMemoAddGet(indexID?: number, memo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameLockMemoAddGet(indexID, memo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 사용 가능여부 체크
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameNicknameCheckGet(nickname?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResponseModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameNicknameCheckGet(nickname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 닉네임 설정 (신규/수정)
         * @param {string} [nickname] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NicknameNicknameSetGet(nickname?: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NicknameResponseModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NicknameNicknameSetGet(nickname, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NicknameApi - factory interface
 * @export
 */
export const NicknameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NicknameApiFp(configuration)
    return {
        /**
         * 
         * @summary 닉네임 잠금/승인/반려
         * @param {number} [indexID] 
         * @param {number} [approvalStatus] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameChangeStatusUpdateGet(indexID?: number, approvalStatus?: number, comment?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1NicknameChangeStatusUpdateGet(indexID, approvalStatus, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryInfoGet(nickName?: string, options?: any): AxiosPromise<NicknameResultModelApiResult> {
            return localVarFp.v1NicknameHistoryInfoGet(nickName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 정지
         * @param {NicknameLockModel} [nicknameLockModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryLockPost(nicknameLockModel?: NicknameLockModel, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1NicknameHistoryLockPost(nicknameLockModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임의 게시물 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoBoardListGet(nickName?: string, options?: any): AxiosPromise<NicknameResultModelApiResult> {
            return localVarFp.v1NicknameHistoryMyinfoBoardListGet(nickName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임의 Comment 정보 확인 (history.vue에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoCommentListGet(nickName?: string, options?: any): AxiosPromise<NicknameResultModelApiResult> {
            return localVarFp.v1NicknameHistoryMyinfoCommentListGet(nickName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (history.vue 이력탭에서 사용)
         * @param {string} [nickName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameHistoryMyinfoHistoryGet(nickName?: string, options?: any): AxiosPromise<NicknameResultModelApiResult> {
            return localVarFp.v1NicknameHistoryMyinfoHistoryGet(nickName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 기본정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameInfoGet(options?: any): AxiosPromise<NicknameInfoModelApiResult> {
            return localVarFp.v1NicknameInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 정보 확인 (lock, change.vue 사용)
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockInfoGet(indexID?: number, options?: any): AxiosPromise<NicknameHistoryModelApiResult> {
            return localVarFp.v1NicknameLockInfoGet(indexID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary nickname unlock 성공은 true, 실패는 fasle, 오류는 success false
         * @param {string} [nickname] 
         * @param {number} [indexID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockListUnlockGet(nickname?: string, indexID?: number, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1NicknameLockListUnlockGet(nickname, indexID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Nickname Memo 업데이트
         * @param {number} [indexID] 
         * @param {string} [memo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameLockMemoAddGet(indexID?: number, memo?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1NicknameLockMemoAddGet(indexID, memo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 사용 가능여부 체크
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameNicknameCheckGet(nickname?: string, options?: any): AxiosPromise<NicknameResponseModelApiResult> {
            return localVarFp.v1NicknameNicknameCheckGet(nickname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 닉네임 설정 (신규/수정)
         * @param {string} [nickname] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NicknameNicknameSetGet(nickname?: string, comment?: string, options?: any): AxiosPromise<NicknameResponseModelApiResult> {
            return localVarFp.v1NicknameNicknameSetGet(nickname, comment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NicknameApi - object-oriented interface
 * @export
 * @class NicknameApi
 * @extends {BaseAPI}
 */
export class NicknameApi extends BaseAPI {
    /**
     * 
     * @summary 닉네임 잠금/승인/반려
     * @param {number} [indexID] 
     * @param {number} [approvalStatus] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameChangeStatusUpdateGet(indexID?: number, approvalStatus?: number, comment?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameChangeStatusUpdateGet(indexID, approvalStatus, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 정보 확인 (history.vue에서 사용)
     * @param {string} [nickName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameHistoryInfoGet(nickName?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameHistoryInfoGet(nickName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 정지
     * @param {NicknameLockModel} [nicknameLockModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameHistoryLockPost(nicknameLockModel?: NicknameLockModel, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameHistoryLockPost(nicknameLockModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임의 게시물 정보 확인 (history.vue에서 사용)
     * @param {string} [nickName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameHistoryMyinfoBoardListGet(nickName?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameHistoryMyinfoBoardListGet(nickName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임의 Comment 정보 확인 (history.vue에서 사용)
     * @param {string} [nickName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameHistoryMyinfoCommentListGet(nickName?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameHistoryMyinfoCommentListGet(nickName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 정보 확인 (history.vue 이력탭에서 사용)
     * @param {string} [nickName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameHistoryMyinfoHistoryGet(nickName?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameHistoryMyinfoHistoryGet(nickName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 기본정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameInfoGet(options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 정보 확인 (lock, change.vue 사용)
     * @param {number} [indexID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameLockInfoGet(indexID?: number, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameLockInfoGet(indexID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary nickname unlock 성공은 true, 실패는 fasle, 오류는 success false
     * @param {string} [nickname] 
     * @param {number} [indexID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameLockListUnlockGet(nickname?: string, indexID?: number, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameLockListUnlockGet(nickname, indexID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Nickname Memo 업데이트
     * @param {number} [indexID] 
     * @param {string} [memo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameLockMemoAddGet(indexID?: number, memo?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameLockMemoAddGet(indexID, memo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 사용 가능여부 체크
     * @param {string} [nickname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameNicknameCheckGet(nickname?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameNicknameCheckGet(nickname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 닉네임 설정 (신규/수정)
     * @param {string} [nickname] 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NicknameApi
     */
    public v1NicknameNicknameSetGet(nickname?: string, comment?: string, options?: AxiosRequestConfig) {
        return NicknameApiFp(this.configuration).v1NicknameNicknameSetGet(nickname, comment, options).then((request) => request(this.axios, this.basePath));
    }
}
