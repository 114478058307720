import moment from 'moment'

export const usePortalCss = () => {
  const timeStamp = moment().format(import.meta.env.V_RESOURCE_TIMESTAMP)

  const object = document.createElement('link')
  object.type = 'text/css'
  object.rel = 'stylesheet'
  object.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/css/object.css?t=${timeStamp}`
  document.head.prepend(object)

  const style = document.createElement('link')
  style.type = 'text/css'
  style.rel = 'stylesheet'
  style.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/css/style.css?t=${timeStamp}`
  document.head.prepend(style)

  const common = document.createElement('link')
  common.type = 'text/css'
  common.rel = 'stylesheet'
  common.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/css/common.css?t=${timeStamp}`
  document.head.prepend(common)

  const pretendard = document.createElement('link')
  pretendard.rel = 'stylesheet'
  pretendard.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/css/fonts/pretendard-subset.css?t=${timeStamp}`
  document.head.prepend(pretendard)

  const preload = document.createElement('link')
  preload.rel = 'preload'
  preload.as = 'font'
  preload.type = 'font/woff2'
  preload.crossOrigin = 'anonymous'
  preload.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/css/fonts/woff2-subset/Pretendard-Regular.subset.woff2`
  document.head.prepend(preload)

  const icon = document.createElement('link')
  icon.rel = 'icon'
  icon.href = `${import.meta.env.V_RESOURCE_BASE_PATH}/images/favicon.ico?t=${timeStamp}`
  document.head.prepend(icon)
}
