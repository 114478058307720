<template>
  <div>
    <!-- @vue-ignore -->
    <q-file
      v-model="item"
      clearable
      dense
      hide-details
      :label="props.placeholder"
      outlined
      :style="`width: ${props.width}; min-width: 200px`"
      variant="underlined" />
    <div class="row q-mt-sm">
      <!-- @vue-ignore -->
      <img
        v-if="props.usePreview"
        class="image-preview"
        :src="
          // @ts-ignore
          getConvertedSrc(src)
        "
        :style="`width:calc(${props.width} - 28px);height: auto;max-height:${props.maxHeight};`" />
      <!-- @vue-ignore -->
      <q-icon
        v-if="props.usePreview && src"
        class="close"
        color="grey"
        name="close"
        size="20px"
        @click="
          // @ts-ignore
          item = null
        " />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  modelValue?: File | undefined | null
  base64?: string | ArrayBuffer | null
  fileName?: string | null | undefined
  usePreview?: boolean
  placeholder?: string
  width?: string
  maxHeight?: string
  maxSize?: number | undefined
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  usePreview: false,
  base64: null,
  fileName: '',
  placeholder: '이미지를 선택하세요',
  width: '200px',
  maxHeight: 'auto',
  maxSize: undefined
})

const emit = defineEmits<{
  (e: 'add', value: string): void
  (e: 'update:modelValue', value: File | undefined | null): void
  (e: 'update:base64', value: string | ArrayBuffer | null): void
  (e: 'update:fileName', value: string | null): void
}>()

const item: Ref<File | undefined | null> = ref()
watch(item, (file) => {
  readURL()
  emit('update:modelValue', file)
})

const src: Ref<string | ArrayBuffer | null> = computed({
  get() {
    if (props.base64 && typeof props.base64 === 'string' && props.fileName && !item.value) {
      dataURLtoFile(props.base64, props.fileName)
    }
    return props.base64
  },
  set(value) {
    emit('update:base64', value)
  }
})

/** src 가 ArrayBuffer이면 base64 string으로 변환  */
const getConvertedSrc = (src: string | ArrayBuffer | null) => {
  if (src == null) {
    return undefined
  } else if (src instanceof ArrayBuffer) {
    return arrayBufferToBase64(src)
  } else {
    return src
  }
}

/** ArrayBuffer 를 base64 string으로 변환 */
const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return btoa(binary)
}

const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',')
  if (arr.length > 0) {
    const match = arr[0].match(/:(.*?);/)
    if (match && match.length > 0) {
      const mime = match[1]
      const bstr = atob(arr[arr.length - 1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      item.value = new File([u8arr], filename, { type: mime })
    }
  }
}

function readURL() {
  if (item.value) {
    const reader = new FileReader()

    reader.onload = function (e) {
      if (e.target) {
        src.value = e.target.result
      }
    }
    reader.readAsDataURL(item.value)
    emit('update:fileName', item.value?.name ?? '')
  } else {
    src.value = null
    emit('update:fileName', '')
  }
}
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
  align-items: center;
}

.image-preview {
  object-fit: contain;
}

.close {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}
</style>
