<template>
  <div v-if="isOptionArray && options.length > 0" :class="isHorizontal ? '' : 'vertical'">
    <span v-show="props.useSelectAll" class="span-check">
      <input id="all" v-model="selectAll" :disabled="isDisabled" type="checkbox" :value="selectAll" />
      <label :class="isDisabled ? 'cursor-not-allowed' : ''" for="all"> {{ t('SelectAll', '전체선택') }} </label>
    </span>
    <span v-for="option in options" :key="getCheckBoxValue(option, props.valueProperty)" class="span-check">
      <input
        :id="getCheckBoxValue(option, props.valueProperty)"
        v-model="checked"
        :disabled="isDisabled"
        type="checkbox"
        :value="getCheckBoxValue(option, props.valueProperty)" />
      <label :class="isDisabled ? 'cursor-not-allowed' : ''" :for="getCheckBoxValue(option, props.valueProperty)">
        {{ option[props.labelProperty] }}
      </label>
    </span>
  </div>
  <div v-else-if="!!checkId">
    <span class="span-check">
      <input :id="checkId" v-model="checked" :disabled="isDisabled" type="checkbox" />
      <label :class="isDisabled ? 'cursor-not-allowed' : ''" :for="checkId">
        {{ label }}
      </label>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
interface Props {
  modelValue?: any
  options?: any[]
  valueType?: 'string' | 'number' // option 객체에서 checkbox value로 사용할 프로퍼티 타입
  valueProperty?: string // option 객체에서 checkbox value로 사용할 프로퍼티 명
  labelProperty?: string // option 객체에서 checkbox label로 사용할 프로퍼티 명
  isDisabled?: boolean
  useSelectAll?: boolean
  // checkbox array 가 아닌 하나의 체크박스만 쓸 경우 아래 label, checkID 값이 필요
  label?: string
  checkId?: string
  // 가로 / 세로 정렬 여부 - default 가로
  isHorizontal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  options: () => [],
  valueProperty: '',
  labelProperty: '',
  valueType: 'string',
  isDisabled: false,
  useSelectAll: false,
  label: '',
  checkId: '',
  isHorizontal: true
})

/** 회의실 도구 선택 - 전체 선택 */
const selectAll = computed({
  get() {
    return checked.value.length === props.options.length
  },
  set(value) {
    checked.value = []
    if (value) {
      const valueArray: any[] = []
      props.options.forEach((option) => {
        valueArray.push(getCheckBoxValue(option, props.valueProperty))
      })
      emit('update:modelValue', valueArray)
    }
  }
})

const checked = computed({
  get() {
    return props.modelValue ?? []
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

/** v-for 문을 돌려 checkbox를 렌더링할지 여부를 확인 */
const isOptionArray = computed(() => {
  return Array.isArray(props.options)
})

/** value 값이 number 인지 string 인지 확인 후 parsing */
const getCheckBoxValue = (option: any, valueProperty: string) => {
  if (valueProperty && `${valueProperty}` in option) {
    const value = option[valueProperty]
    if (props.valueType === 'number') {
      const parsedValue = parseInt(value)
      return parsedValue ? parsedValue : -1
    } else {
      return value
    }
  }
}

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void
}>()
</script>

<style scoped lang="scss">
.vertical {
  display: flex;
  flex-direction: column;

  .span-check {
    margin-bottom: 5px;
  }
}
.span-check {
  display: inline-block;
  margin-right: 5px;

  input[type='checkbox'] {
    display: none;

    &:checked + label {
      background-color: #7475e1;
      border: 1px solid #7475e1;
      color: #fff;
      font-weight: bold;
    }

    // 공통 디자인과 겹쳐서 checkbox의 check 버튼이 나오는 현상이 있어 추가//
    & + label::before {
      content: none;
    }
  }

  label {
    display: inline-block;
    background: #f5f5f5;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #888;
    font-size: 14px;
    padding: 8px 16px;
    cursor: pointer;
  }
}
</style>
