<template>
  <!-- @vue-ignore -->
  <q-input v-model="value" :readonly="isReadonly" dense :disable="isDisable" @keypress.enter.prevent="searchClick">
    <template #append>
      <q-icon name="search" style="cursor: pointer" @click.prevent="searchClick" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: string
  isReadonly?: boolean
  isDisable?: boolean
  searchClick?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  isReadonly: false,
  isDisable: false,
  searchClick: () => { }
})

const value = computed({
  get() {
    return props.modelValue
  },
  set(val: string) {
    emit('update:modelValue', val)
  }
})

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()
</script>
