<template>
  <!-- @vue-ignore -->
  <q-select
    ref="qSelectRef"
    v-model="value"
    dense
    :emit-value="props.emitValue"
    fill-input
    :hide-selected="props.useFilter"
    input-debounce="0"
    :map-options="props.mapOptions"
    :options="props.useFilter ? filteredOptions : props.options"
    outlined
    :use-input="props.useFilter"
    :value="inputValue"
    :disable="props.disable"
    :popup-content-style="resizable()"
    @input-value="setModel" />
</template>

<script setup lang="ts">
interface Props {
  modelValue: any
  /** 전체선택 버튼 사용 여부 */
  useSelectAll?: boolean
  options?: any[]
  /** 전체선택 여부: v-model */
  selectAll?: boolean
  /** 검색 필터 사용 여부 */
  useFilter?: boolean
  /** 필터링 할 프로퍼티명 */
  filterProperty?: string
  /** options 중 첫번째 요소를 자동 선택할지 여부 */
  selectFirst?: boolean
  /** 전체 옵션 대신 선택한 옵션의 값으로 모델 업데이트 */
  emitValue?: boolean
  mapOptions?: boolean
  /** Select DropDown 최대 표시 수 */
  maxItemCount?: number
  /** 비활성화 여부 */
  disable?: boolean
  /** popup-content-style 의 z-index 사용 */
  zIndex?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  useSelectAll: false,
  selectAll: false,
  options: () => [],
  useFilter: false,
  filterProperty: '',
  selectFirst: true,
  emitValue: true,
  mapOptions: true,
  maxItemCount: 5,
  disable: false,
  zIndex: false
})

const qSelectRef = ref()

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:model-value', value)
  }
})

const inputValue = ref('')

const filteredOptions = ref([])

const setModel = (val: string) => {
  inputValue.value = val
}

const resizable = () => {
  let list: any[] = new Array()
  list = props.useFilter ? filteredOptions.value : props.options
  const zIndexStr = props.zIndex ? 'z-index:10' : ''
  if (list.length <= props.maxItemCount) {
    return 'height: ' + list.length * 32 + 'px;' + zIndexStr
  } else {
    return 'height: ' + props.maxItemCount * 32 + 'px;' + zIndexStr
  }
}

watch(
  () => props.options,
  (newVal) => {
    filteredOptions.value = Object.assign([], newVal)
  }
)

const emit = defineEmits<{
  (e: 'update:model-value', value: any): void
  (e: 'update:options', value: any): void
}>()

onMounted(() => {
  if (props.useFilter && props.filterProperty) {
    qSelectRef.value.$el.querySelector('input').addEventListener('input', function (e: InputEvent) {
      const target = e.target as HTMLInputElement
      inputValue.value = target.value

      if (!inputValue.value) {
        filteredOptions.value = Object.assign([], props.options)
      } else {
        const temp = props.options.filter((v) => {
          return v[props.filterProperty].toLocaleLowerCase().indexOf(inputValue.value) > -1
        })
        filteredOptions.value = Object.assign([], temp)
      }
    })
  }
})
</script>
