<template>
  <div class="mik-org">
    <input
      ref="inputRef"
      class="mik-org-input"
      type="text"
      :placeholder="placeholder"
      :value="textValue"
      autocomplete="off"
      v-bind="$attrs"
      :disabled="props.isDisable"
      @input="handleKeyword"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="selectCurrentSelection"
      @keydown.delete="deleteInput"
      @keydown.tab.prevent="selectCurrentSelectionTab" />

    <div v-if="isListVisible" ref="mikUserList" class="autocomplete-suggestions">
      <!-- @vue-ignore -->
      <div
        v-for="(item, index) in filteredItems"
        :key="index"
        ref="mikUserListItem"
        :class="getClass(index)"
        @mousedown.prevent
        @click.prevent="selectItem(item)"
        @mouseenter="handleMouseEnter(index)">
        <div v-show="item.entryType == 2" class="photo-box">
          <img class="photo" :src="`${profileUrl}${item.userID}`" />
        </div>
        <div class="description-box">
          <div class="name" v-html="boldMatchText(item.displayName)"></div>
          <div class="email">{{ item.emailAddress }}</div>
        </div>
      </div>
    </div>
    <mik-button
      :button-txt="$t(buttonText)"
      :button-type="'secondary'"
      class="orgchart-btn"
      :disable="props.isDisable"
      @click.prevent="clickOrgchart" />
    <div class="display_tag">
      <div class="tag_dept">
        <i18n-t keypath="CommonOrgAutoMessage1" tag="span" class="txt">
          {{ deptCount }}
        </i18n-t>
        <!-- 부서가 {{ deptCount }}개 선택되었습니다 -->
        <ul class="tag_dept_ul">
          <li v-for="(selected, index) of deptList" :key="`dept_${index}`" class="tag_dept_li">
            <span class="tag_dept_li_name">{{ selected.displayName }}</span>
            <svg class="tag_delete" width="20" height="20" @click.prevent="deleteSelectedItem(selected)">
              <g class="svg_delete_btn">
                <line x1="4" y1="4" x2="16" y2="16" stroke="#000000" stroke-width="1.2"></line>
                <line x1="16" y1="4" x2="4" y2="16" stroke="#000000" stroke-width="1.2"></line>
              </g>
            </svg>
          </li>
        </ul>
      </div>
      <div class="tag_user">
        <i18n-t keypath="CommonOrgAutoMessage2" tag="span" class="txt">
          {{ userCount }}
        </i18n-t>
        <!-- 사용자 {{ userCount }}명이 선택되었습니다 -->
        <ul class="tag_user_ul">
          <li v-for="(selected, index) of userList" :key="`user_${index}`" class="tag_user_li">
            <span class="tag_user_li_name">{{ selected.displayName }}</span>
            <svg class="tag_delete" width="20" height="20" @click.prevent="deleteSelectedItem(selected)">
              <g class="svg_delete_btn">
                <line x1="4" y1="4" x2="16" y2="16" stroke="#000000" stroke-width="1.2"></line>
                <line x1="16" y1="4" x2="4" y2="16" stroke="#000000" stroke-width="1.2"></line>
              </g>
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// @ts-nocheck
import axios from 'axios'
import OrgModel from '../../model/user/OrgModel'
import { useToast } from '../../store/useToast'
import { om_OpenOrgChart, upperCaseKeys, lowerCaseKeys } from '../../utils/orgchart'
import { useI18n } from 'vue-i18n'
import MikButton from '../common/MikButton.vue'

export interface Props {
  modelValue?: OrgModel[]
  placeholder?: string
  selectedItem?: OrgModel[]
  maxLengthItem?: number
  selectOnTab?: boolean
  searchUrl?: string
  profileUrl?: string
  isDisable?: boolean
  buttonText?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  placeholder: '',
  selectedItem: () => [],
  maxLengthItem: 50,
  selectOnTab: true,
  searchUrl: `${import.meta.env.V_API_ORGCHART_PATH}/api/v1/User/SearchAutocomplete`,
  profileUrl: `${import.meta.env.V_API_GLOBALMENU_PATH}/v1/resource/image/profile/`,
  isDisable: false,
  buttonText: 'OrgChart'
})

const inputRef = ref()
const mikUserList = ref()
const mikUserListItem = ref()

const textValue = ref('')
const isInputFocused = ref(false)
const currentSelectionIndex = ref(0)
let emptyValue = false

const selectedItemArr: OrgModel[] = reactive([])
const filteredItems: OrgModel[] = reactive([])
// const selectedItemArr = computed({
//   get() {
//     return props.modelValue
//   },
//   set(value) {
//     emit('update:modelValue', value)
//   }
// })

const toast = useToast()
const { t } = useI18n()

let intervalId: number = 0

onMounted(() => {
  if (props.selectedItem != null) {
    props.selectedItem.forEach((item) => {
      selectItem(item)
    })
  }

  // OrgModel이 조직도에서는 파스칼case로 사용하고, 현재 v2에서는 카멜case로 사용되어 혼동이 있다.
  // 조직도: 파스칼case, 포탈v2 Client: 카멜case, 포탈v1 DB: 파스칼case
  if (props.modelValue != null) {
    Object.assign(
      selectedItemArr,
      props.modelValue.map((item) => lowerCaseKeys(item))
    )
  }
})

const deptList = computed(() => {
  return selectedItemArr.filter((o) => o.entryType == 0) ?? []
})

const deptCount = computed(() => {
  return selectedItemArr.filter((o) => o.entryType == 0)?.length ?? 0
})

const userList = computed(() => {
  return selectedItemArr.filter((o) => o.entryType == 2) ?? []
})

const userCount = computed(() => {
  return selectedItemArr.filter((o) => o.entryType == 2)?.length ?? 0
})

const getClass = (index: number) => {
  return {
    'autocomplete-suggestion': true,
    'autocomplete-selected': currentSelectionIndex.value === index
  }
}

const handleMouseEnter = (index: number) => {
  currentSelectionIndex.value = index
}

const handleKeyword = (event: Event) => {
  const target = event.target as HTMLInputElement
  textValue.value = target.value

  stopKillSuggestions()
  intervalId = window.setTimeout(async () => {
    await getSuggestItem(textValue.value)

    stopKillSuggestions()
  }, 500)

  if (textValue.value == '') {
    emptyValue = true
  } else {
    emptyValue = false
  }
}

const stopKillSuggestions = () => {
  window.clearTimeout(intervalId)
}

const getSuggestItem = async (val: string) => {
  if (val.length > 1) {
    const response = await axios.get(`${props.searchUrl}?companyCode=&keyword=${val}&langCode=${'ko'}`)

    if (response.data.success) {
      filteredItems.splice(0)
      Object.assign(filteredItems, response.data.data)
    }
  }
}

const onFocus = () => {
  isInputFocused.value = true
  //context.emit('onFocus', { input: input, items: filteredItems });
}

const onBlur = () => {
  isInputFocused.value = false
  //context.emit('onBlur', { input: input, items: filteredItems });
}

const onArrowUp = () => {
  if (isListVisible.value && currentSelectionIndex.value > 0) {
    currentSelectionIndex.value--
  }
  scrollSelectionIntoView()
}

const onArrowDown = () => {
  if (isListVisible.value && currentSelectionIndex.value < filteredItems.length - 1) {
    currentSelectionIndex.value++
  }
  scrollSelectionIntoView()
}

const scrollSelectionIntoView = () => {
  setTimeout(() => {
    const list_node: HTMLElement = mikUserList.value
    const active_node: HTMLElement = mikUserListItem.value[currentSelectionIndex.value]

    if (
      !(
        active_node.offsetTop >= list_node.scrollTop &&
        active_node.offsetTop + active_node.offsetHeight < list_node.scrollTop + list_node.offsetHeight
      )
    ) {
      let scroll_to = 0
      if (active_node.offsetTop > list_node.scrollTop) {
        scroll_to = active_node.offsetTop + active_node.offsetHeight - list_node.offsetHeight
      } else if (active_node.offsetTop < list_node.scrollTop) {
        scroll_to = active_node.offsetTop
      }

      list_node.scrollTo(0, scroll_to)
    }
  })
}

const selectCurrentSelection = () => {
  if (currentSelection.value) {
    selectItem(currentSelection.value)
  }
}

const selectCurrentSelectionTab = () => {
  if (props.selectOnTab) {
    selectCurrentSelection()
  } else {
    inputRef.value.blur()
  }
}

const selectItem = (item: OrgModel) => {
  inputRef.value.blur()
  currentSelectionIndex.value = 0
  // 선택 후 초기화
  filteredItems.splice(0)
  textValue.value = ''
  inputRef.value.focus()
  emptyValue = true

  if (selectedItemArr.length < props.maxLengthItem) {
    if (selectedItemArr.filter((selected: OrgModel) => selected.userID == item.userID).length == 0) {
      selectedItemArr.push(item)
      emit('update:modelValue', selectedItemArr)
    } else {
      // 중복
      toast.warning(t('SearchUserDuplicateUsers', [item.userName]))
    }
  } else {
    // 한계 초과
    toast.warning(t('MaxUser'))
  }
}

const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const boldMatchText = (text: string) => {
  const regexp = new RegExp(`(${escapeRegExp(textValue.value)})`, 'ig')
  return text.replace(regexp, '<strong>$1</strong>')
}

const clearInput = () => {
  textValue.value = ''
}

const getInput = (): HTMLElement => {
  return inputRef.value
}

const focusInput = () => {
  inputRef.value.focus()
  onFocus()
}

const blurInput = () => {
  inputRef.value.blur()
  onBlur()
}

defineExpose({
  clearInput,
  getInput,
  focusInput,
  blurInput
})

const isListVisible = computed(() => {
  return isInputFocused.value && filteredItems.length > 0
})

const currentSelection = computed(() => {
  return isListVisible.value && currentSelectionIndex.value < filteredItems.length
    ? filteredItems[currentSelectionIndex.value]
    : undefined
})

const deleteSelectedItem = (orgItem: OrgModel) => {
  let index = -1
  if (orgItem.entryType == 0) {
    index = selectedItemArr.findIndex((item) => item.deptCode == orgItem.deptCode && !item.userID)
  } else if (orgItem.entryType == 2) {
    index = selectedItemArr.findIndex((item) => item.userID == orgItem.userID)
  }

  if (index != -1) {
    selectedItemArr.splice(index, 1)
    emit('update:modelValue', selectedItemArr)
  }
}

const clickOrgchart = () => {
  const orgData = []
  for (const select of selectedItemArr) {
    // @ts-ignore
    orgData.push(upperCaseKeys<OrgModel>(select))
  }

  om_OpenOrgChart({
    appType: 'deptuser',
    oneSelect: props.maxLengthItem == 1,
    data: JSON.stringify(orgData),
    callback: (data: string) => {
      const items = JSON.parse(data)

      if (items != null && items.length > 0) {
        selectedItemArr.splice(0)
        for (const item of items) {
          if (selectedItemArr.length < props.maxLengthItem) {
            // @ts-ignore
            selectedItemArr.push(lowerCaseKeys(item))
          } else {
            // 한계 초과
            toast.warning(t('MaxUser'))
            emit('update:modelValue', selectedItemArr)
            break
          }
        }
        emit('update:modelValue', selectedItemArr)
      }
    }
  })
}

const deleteInput = () => {
  if (emptyValue) {
    deleteSelectedItem(selectedItemArr.length - 1)
  }
}
const emit = defineEmits<{ (e: 'update:modelValue', value: OrgModel[]): void }>()

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue != null) {
      Object.assign(
        selectedItemArr,
        props.modelValue.map((item) => lowerCaseKeys(item))
      )
    }
  }
)
</script>

<style scoped>
.mik-org {
  position: relative;
  padding: 4px 5px 0px 5px;
  width: 100%;
  border: 1px solid #d8d8d8;
}

.mik-org > input {
  margin-bottom: 0;
}

.mik-org .orgchart-btn {
  margin-left: 5px;
  margin-bottom: 4px;
}

.mik-org .autocomplete-suggestions {
  position: absolute;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 20;
  min-width: 300px;
}

.mik-org .autocomplete-suggestions.nano {
  overflow: hidden;
}

.mik-org .autocomplete-suggestions div {
  margin: 0;
  padding: 0;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion {
  background-color: white;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8;
  text-align: left;
  padding: 2px 0;
  cursor: pointer;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion:first-child {
  padding: 0 0 2px;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  background: #f0f0f0;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion:first-of-type {
  margin-top: 0;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .photo-box,
.description-box {
  display: inline-block;
  vertical-align: top;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .photo-box img {
  height: 60px;
  width: 60px;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .photo-box img[src=''] {
  display: none;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box {
  padding: 5px 5px 0 15px;
  margin-top: -2px;
  width: calc(100% - 85px);
  height: 100%;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box .name,
.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box .email {
  overflow: hidden;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box .name {
  font-size: 16px;
  line-height: 16px;
  margin-top: 9px;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box .email {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.mik-org .autocomplete-suggestions .autocomplete-suggestion > .description-box .name,
.email {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mik-org .mik-org-input {
  height: 36px;
  border: 1px solid #d8d8d8;
  min-width: 200px;
}

.mik-org .mik-org-input .taginput_edit {
  width: 100%;
  box-sizing: border-box;
}

.mik-org .display_tag {
  display: flex;
}

.mik-org .tag_dept {
  flex: 1;
}

.mik-org .tag_user {
  flex: 1;
}

.mik-org .tag_dept_ul {
  border: 1px solid #d8d8d8;
  height: 150px;
  margin: 5px 2px 5px 0px;
  padding: 0px;
}

.mik-org .tag_user_ul {
  border: 1px solid #d8d8d8;
  height: 150px;
  margin: 5px 0px 5px 2px;
  padding: 0px;
  overflow-y: auto;
}

.mik-org .tag_dept_li {
  min-height: 21px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  width: 100%;
  padding: 5px;
  margin: 0px;
  overflow-y: auto;
}

.mik-org .tag_user_li {
  min-height: 21px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  width: 100%;
  padding: 5px;
  margin: 0px;
}

.mik-org .tag_user_li_name {
  flex: 5;
}

.mik-org .tag_dept_li_name {
  flex: 5;
}

.mik-org .tag_delete {
  cursor: pointer;
}
</style>
