<template>
  <!-- @vue-ignore -->
  <q-input
    ref="dateInput"
    v-model="textDate"
    dense
    :mask="inputMask"
    :style="`width: ${width}px`"
    :debounce="1000"
    @click="clickInput">
    <template #append>
      <q-icon name="event" class="cursor-pointer">
        <!-- @vue-ignore -->
        <q-popup-proxy v-model="showPopup" transition-show="scale" transition-hide="scale" :offset="[width - 55, 10]">
          <div class="q-gutter-md row items-start">
            <!-- @vue-ignore -->
            <q-date
              :model-value="modelValue"
              :minimal="!enableTimePicker"
              today-btn
              :options="options"
              :mask="mask"
              :readonly="isReadOnly"
              :disable="isDisable"
              :range="enablePeriod"
              :locale="myLocale[langCode]"
              :default-year-month="defaultYearMonth"
              @update:model-value="selectDate">
              <div class="row items-center justify-end">
                <q-btn v-close-popup :label="$t('Close')" color="primary" flat />
              </div>
            </q-date>
            <!-- @vue-ignore -->
            <!-- @vue-skip -->
            <q-time
              v-if="enableTimePicker"
              :model-value="modelValue"
              minimal
              :mask="mask"
              :readonly="isReadOnly"
              :disable="isDisable"
              @update:model-value="selectTime">
              <div class="row items-center justify-end">
                <q-btn v-close-popup :label="$t('Close')" color="primary" flat />
              </div>
            </q-time>
          </div>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script setup lang="ts">
// @ts-nocheck
import moment from 'moment'
import { formatDate } from '../../common/common'
import { Cookies } from 'quasar'
export interface DatePickerInfo {
  from: string
  to: string
}

export interface Props {
  isReadOnly?: boolean
  isDisable?: boolean
  enableTimePicker?: boolean
  modelValue?: DatePickerInfo | string | null | undefined
  label?: string
  enablePeriod?: boolean
  // enablePeriod 사용 시 아래 minDate, maxDate와 호환이 되지 않음.
  minDate?: string
  maxDate?: string
  width?: number
  defaultYearMonth?: string | undefined
}

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
  isDisable: false,
  enableTimePicker: false,
  modelValue: '',
  label: '',
  enablePeriod: false,
  // enablePeriod 사용 시 아래 minDate, maxDate와 호환이 되지 않음.
  minDate: '1900-01-01',
  maxDate: '2999-01-01',
  width: 210,
  defaultYearMonth: undefined
})

const dateInput = ref()
const showPopup = ref<boolean>(false)
const inputMask = ref('')
const mask = ref('')

const textDate = computed({
  get() {
    if (props.modelValue == null) {
      return ''
    } else if (typeof props.modelValue === 'object') {
      return `${props.modelValue.from} ~ ${props.modelValue.to}`
    } else {
      return props.modelValue
    }
  },
  set(val) {
    if (props.enablePeriod && typeof val === 'string') {
      val = (() => {
        const [from, to] = val.split(' ~ ')
        return { from, to }
      })()
    }

    emit('update:modelValue', val)
  }
})

const clickInput = () => {
  //showPopup.value = true
}

const selectDate = (
  value: any,
  reason: 'mask' | 'add-day' | 'remove-day' | 'add-range' | 'remove-range' | 'locale' | 'year' | 'month',
  details: any
) => {
  if (!props.enableTimePicker) {
    if (reason == 'add-day' || reason == 'add-range') {
      showPopup.value = false
    }
  }

  if (value == null) {
    if (props.enablePeriod) {
      emit('update:modelValue', { to: '', from: '' })
    } else {
      emit('update:modelValue', '')
    }
  } else {
    emit('update:modelValue', value)
  }
}

const selectTime = (value: any) => {
  // 시간 체크가 불편해서 제거
  //showPopup.value = false

  emit('update:modelValue', value)
}

const options = (date: string): boolean => {
  // console.log(`props.minDate: ${props.minDate}, props.maxDate: ${props.maxDate}`)
  // console.log(`date : ${date}`)
  date = date.replaceAll('/', '-')

  if (props.minDate && props.maxDate) {
    const min = formatDate(props.minDate)
    const max = formatDate(props.maxDate)

    return date >= min && date <= max
  } else if (props.minDate) {
    const min = formatDate(props.minDate)

    return date >= min
  } else if (props.maxDate) {
    const max = formatDate(props.maxDate)

    return date <= max
  }

  return true
}

// 타임 옵션은 이상 동작으로 인제 사용안함
const timeOptions = (hour: number, minutes: number, seconds: number): boolean => {
  const minDate = moment(props.minDate)

  console.log(`hour: ${hour}, minutes: ${minutes}`)
  // console.log(`minDate: ${minDate}, targetDate.value: ${targetDate.value}`)

  if (formatDate(minDate) == formatDate(props.modelValue)) {
    if (hour && minutes) {
      console.log(`minDate.minutes: ${minDate.minutes()}, test: ${minDate.hours() > hour}`)
      if (minDate.hours() > hour && minDate.minutes() >= minutes) {
        console.log('catch')
        return false
      }
    } else if (hour) {
      // console.log(`minDate.hours: ${minDate.hours()}`)
      if (minDate.hours() > hour) {
        return false
      }
    }
  }

  return true
}

if (props.enablePeriod == false) {
  if (props.enableTimePicker) inputMask.value = `####-##-## ##:##`
  else inputMask.value = `####-##-##`
} else {
  if (props.enableTimePicker) {
    inputMask.value = `####-##-## ##:## ~ ####-##-## ##:##`
  } else {
    inputMask.value = `####-##-## ~ ####-##-##`
  }
}

if (props.enableTimePicker) mask.value = `YYYY-MM-DD HH:mm`
else mask.value = `YYYY-MM-DD`

const langCode = Cookies.get('GWP_LANGUAGE_CODE')
const myLocale = {
  ko: {
    days: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split(''),
    daysShort: '일_월_화_수_목_금_토'.split('_'),
    months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  },
  en: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(''),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  },
  zh: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(''),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 0, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dias'
  }
}

const emit = defineEmits<{ (e: 'update:modelValue', value: DatePickerInfo | string | undefined): void }>()
</script>
