<template>
  <q-btn
    :label="props.buttonTxt"
    type="button"
    class="btn"
    :color="props.buttonType"
    :style="props.styleObj"
    :class="props.classObj"
    :size="props.size"
    @click.prevent="clickBtn" />
</template>

<script setup lang="ts">
export interface Props {
  buttonTxt?: string
  classObj?: any
  buttonType?: 'primary' | 'secondary' | 'accent' | 'info' | 'standard'
  styleObj?: any
  clickFunction?: (() => {}) | Function | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const props = withDefaults(defineProps<Props>(), {
  buttonTxt: '',
  classObj: () => {},
  buttonType: 'primary',
  styleObj: () => {},
  clickFunction: null,
  size: 'md'
})

const clickBtn = () => {
  if (props.clickFunction && typeof props.clickFunction === 'function') {
    props.clickFunction()
  }
}
</script>
<style scoped>
.btn {
  font-weight: bold;
  font-size: 20px;
}
</style>
