import axios from 'axios'
import moment from 'moment'
import { Cookies } from 'quasar'
import type { RouteLocationNormalized } from 'vue-router'

import { withModules, PortalModule } from './portal-module'
import i18n from '../lang/lang'

const timeStamp = moment().format(import.meta.env.V_RESOURCE_TIMESTAMP)
// 현재 다국어만 호출할 수 있도록 수정
export const loadLanguageAsync = async (lang: string, module: PortalModule) => {
  const response = await axios.get(
    `${import.meta.env.V_RESOURCE_BASE_PATH}/locales${module.langPath}/${lang}.json?t=${timeStamp}`
  )
  if (response.status == 200) {
    i18n.global.mergeLocaleMessage(lang, response.data)
    module.loadedLanguage = true
  }
}

export const loadLanguageForModule = async (to: RouteLocationNormalized | string) => {
  const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

  let pathname = ''
  if (typeof to !== 'string') {
    const smartPortalModulePath = to.meta?.smartPortalModulePath
    if (typeof smartPortalModulePath == 'string') {
      pathname = smartPortalModulePath?.toLowerCase()
    } else {
      pathname = `/${to.fullPath.split('/')[1]}`.toLowerCase()
    }
  } else {
    pathname = to.toLowerCase()
  }
  console.log('loadLanguageForModule', pathname, to)
  const module: PortalModule = withModules[pathname]

  if (module && !module.loadedLanguage) {
    await loadLanguageAsync(langCode, module)
  }
}
