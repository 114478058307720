<template>
  <q-img
    ref="imagecontainer"
    :src="src"
    :no-spinner="noSpinner"
    spinner-color="gray"
    loading="lazy"
    @error="handleError">
    <template #error>
      <div class="absolute-full flex flex-center" style="background-color: lightsteelblue">
        <q-icon name="broken_image" :size="errorIconSize" />
      </div>
    </template>
  </q-img>
</template>

<script setup lang="ts">
// @ts-nocheck
export interface Props {
  src: string
  noSpinner?: boolean
  // Icon 사이즈는 width의 3분의 1정도로 세팅한다.
  errorIconSize?: string
  errorCallback?: any | null
}

const props = withDefaults(defineProps<Props>(), {
  src: undefined,
  noSpinner: false,
  errorIconSize: '33px',
  errorCallback: null
})

const handleError = () => {
  if (props.errorCallback) {
    props.errorCallback(props.src)
  }
}
</script>
