export interface PortalModule {
  basePath: string
  langPath:
    | '/board'
    | '/front'
    | '/companyrule'
    | '/consentform'
    | '/notification'
    | '/community'
    | '/survey'
    | '/subportal'
    | '/workplanner'
    | '/reservation'
  loadedLanguage: boolean
  routers: any
}

/// 포함 될 모듈
export const withModules: Record<string, PortalModule> = {}
