<template>
  <div class="error_container">
    <div class="top">
      <div class="ico_alert_all ico_alert_all_notfound"></div>
    </div>
    <h5 style="font-weight: bold">404 Not Found</h5>
    <!-- {{ $route.params.everyPath }} -->
    <div class="bottom">
      <div class="message main"></div>
    </div>

    <!-- <div class="text-center py-5">
      <div class="mt-4">
        <a href="" @click.prevent="prevPageUrl">
          <button class="btn btn-primary" style="font-weight: bold; font-color: blue">go prev Page</button>
        </a>
      </div>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

//const messageMain = ref('')
//const messageSub = ref('')
//const { t } = useI18n()
const router = useRouter()

const prevPageUrl = () => {
  router.go(-1)
}
</script>

<style scoped>
.error_container {
  min-width: 300px;
  max-width: 900px;
  width: 80%;
  margin: 100px auto;
  padding: 0px;
  text-align: center;
}

.error_container .top {
  margin: 20px auto;
}

.error_container .bottom {
  margin: 10px auto;
}

.error_container .bottom .message {
  margin: 10px 0px;
}

.error_container .bottom .main {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.error_container .bottom .sub {
  font-size: 1em;
  font-weight: normal;
  color: #aaa;
}

.ico_alert_all {
  display: inline-block;
  background: url(/PortalResources/images/popup/img_alert.png) no-repeat;
  vertical-align: middle;
}

.ico_alert_all_accessdenied {
  width: 80px;
  height: 80px;
  background-position: -100px top;
}

.ico_alert_all_error {
  width: 80px;
  height: 80px;
  background-position: -400px top;
}

.ico_alert_all_notfound {
  width: 142px;
  height: 56px;
  background-position: -600px top;
}
</style>
