/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { RecentlySearchMenuModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { RecentlyUsedMenuModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { RecentlyUsedMenuRequest } from '../models';
// @ts-ignore
import type { SearchMenuMasterListModelApiResult } from '../models';
/**
 * RecentlyMenuApi - axios parameter creator
 * @export
 */
export const RecentlyMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuDelete: async (sortOrder?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlySearchMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlySearchMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuPost: async (bucketId?: string, recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlySearchMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recentlyUsedMenuRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlyUsedMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlyUsedMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuPost: async (recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/RecentlyMenu/recentlyUsedMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recentlyUsedMenuRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [langCode] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [bucketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuSearchMenuMasterKeywordGet: async (keyword: string, langCode?: string, pageNumber?: number, pageSize?: number, bucketId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('v1RecentlyMenuSearchMenuMasterKeywordGet', 'keyword', keyword)
            const localVarPath = `/v1/RecentlyMenu/searchMenuMaster/{keyword}`
                .replace(`{${"keyword"}}`, encodeURIComponent(String(keyword)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (bucketId !== undefined) {
                localVarQueryParameter['bucketId'] = bucketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentlyMenuApi - functional programming interface
 * @export
 */
export const RecentlyMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentlyMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlySearchMenuDelete(sortOrder?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlySearchMenuDelete(sortOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlySearchMenuGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentlySearchMenuModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlySearchMenuGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlySearchMenuPost(bucketId?: string, recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlySearchMenuPost(bucketId, recentlyUsedMenuRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlyUsedMenuDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlyUsedMenuDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlyUsedMenuGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentlyUsedMenuModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlyUsedMenuGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [langCode] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [bucketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RecentlyMenuSearchMenuMasterKeywordGet(keyword: string, langCode?: string, pageNumber?: number, pageSize?: number, bucketId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchMenuMasterListModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RecentlyMenuSearchMenuMasterKeywordGet(keyword, langCode, pageNumber, pageSize, bucketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentlyMenuApi - factory interface
 * @export
 */
export const RecentlyMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentlyMenuApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuDelete(sortOrder?: number, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1RecentlyMenuRecentlySearchMenuDelete(sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuGet(options?: any): AxiosPromise<RecentlySearchMenuModelIEnumerableApiResult> {
            return localVarFp.v1RecentlyMenuRecentlySearchMenuGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketId] 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlySearchMenuPost(bucketId?: string, recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1RecentlyMenuRecentlySearchMenuPost(bucketId, recentlyUsedMenuRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuDelete(options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1RecentlyMenuRecentlyUsedMenuDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuGet(options?: any): AxiosPromise<RecentlyUsedMenuModelIEnumerableApiResult> {
            return localVarFp.v1RecentlyMenuRecentlyUsedMenuGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} keyword 
         * @param {string} [langCode] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [bucketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RecentlyMenuSearchMenuMasterKeywordGet(keyword: string, langCode?: string, pageNumber?: number, pageSize?: number, bucketId?: string, options?: any): AxiosPromise<SearchMenuMasterListModelApiResult> {
            return localVarFp.v1RecentlyMenuSearchMenuMasterKeywordGet(keyword, langCode, pageNumber, pageSize, bucketId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentlyMenuApi - object-oriented interface
 * @export
 * @class RecentlyMenuApi
 * @extends {BaseAPI}
 */
export class RecentlyMenuApi extends BaseAPI {
    /**
     * 
     * @param {number} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlySearchMenuDelete(sortOrder?: number, options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlySearchMenuDelete(sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlySearchMenuGet(options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlySearchMenuGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketId] 
     * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlySearchMenuPost(bucketId?: string, recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlySearchMenuPost(bucketId, recentlyUsedMenuRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlyUsedMenuDelete(options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlyUsedMenuDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlyUsedMenuGet(options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlyUsedMenuGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecentlyUsedMenuRequest} [recentlyUsedMenuRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest?: RecentlyUsedMenuRequest, options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuRecentlyUsedMenuPost(recentlyUsedMenuRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} keyword 
     * @param {string} [langCode] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [bucketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentlyMenuApi
     */
    public v1RecentlyMenuSearchMenuMasterKeywordGet(keyword: string, langCode?: string, pageNumber?: number, pageSize?: number, bucketId?: string, options?: AxiosRequestConfig) {
        return RecentlyMenuApiFp(this.configuration).v1RecentlyMenuSearchMenuMasterKeywordGet(keyword, langCode, pageNumber, pageSize, bucketId, options).then((request) => request(this.axios, this.basePath));
    }
}
