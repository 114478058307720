/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { BookmarkInsertRequest } from '../models';
// @ts-ignore
import type { BookmarkModel } from '../models';
// @ts-ignore
import type { BookmarkModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { BookmarkRequest } from '../models';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
/**
 * BookmarkApi - axios parameter creator
 * @export
 */
export const BookmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkDeleteListPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark/deleteList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1BookmarkIdDelete', 'id', id)
            const localVarPath = `/v1/bookmark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {BookmarkModel} [bookmarkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIdPut: async (id: string, bookmarkModel?: BookmarkModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1BookmarkIdPut', 'id', id)
            const localVarPath = `/v1/bookmark/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIsBookMarkListPost: async (bookmarkInsertRequest?: BookmarkInsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark/IsBookMarkList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkInsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkOnceApplyPost: async (bookmarkInsertRequest?: BookmarkInsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark/onceApply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkInsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkPost: async (bookmarkInsertRequest?: BookmarkInsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkInsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<BookmarkRequest>} [bookmarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkUpdateListPost: async (bookmarkRequest?: Array<BookmarkRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bookmark/updateList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookmarkApi - functional programming interface
 * @export
 */
export const BookmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkDeleteListPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkDeleteListPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {BookmarkModel} [bookmarkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkIdPut(id: string, bookmarkModel?: BookmarkModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkIdPut(id, bookmarkModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkIsBookMarkListPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkIsBookMarkListPost(bookmarkInsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkOnceApplyPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkOnceApplyPost(bookmarkInsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkPost(bookmarkInsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkTestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<BookmarkRequest>} [bookmarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BookmarkUpdateListPost(bookmarkRequest?: Array<BookmarkRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BookmarkUpdateListPost(bookmarkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookmarkApi - factory interface
 * @export
 */
export const BookmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookmarkApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkDeleteListPost(requestBody?: Array<string>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BookmarkDeleteListPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkGet(options?: any): AxiosPromise<BookmarkModelIEnumerableApiResult> {
            return localVarFp.v1BookmarkGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIdDelete(id: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BookmarkIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {BookmarkModel} [bookmarkModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIdPut(id: string, bookmarkModel?: BookmarkModel, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BookmarkIdPut(id, bookmarkModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkIsBookMarkListPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BookmarkIsBookMarkListPost(bookmarkInsertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkOnceApplyPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1BookmarkOnceApplyPost(bookmarkInsertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1BookmarkPost(bookmarkInsertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkTestGet(options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1BookmarkTestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<BookmarkRequest>} [bookmarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BookmarkUpdateListPost(bookmarkRequest?: Array<BookmarkRequest>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1BookmarkUpdateListPost(bookmarkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookmarkApi - object-oriented interface
 * @export
 * @class BookmarkApi
 * @extends {BaseAPI}
 */
export class BookmarkApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkDeleteListPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkDeleteListPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkGet(options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkIdDelete(id: string, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {BookmarkModel} [bookmarkModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkIdPut(id: string, bookmarkModel?: BookmarkModel, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkIdPut(id, bookmarkModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkIsBookMarkListPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkIsBookMarkListPost(bookmarkInsertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkOnceApplyPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkOnceApplyPost(bookmarkInsertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookmarkInsertRequest} [bookmarkInsertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkPost(bookmarkInsertRequest?: BookmarkInsertRequest, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkPost(bookmarkInsertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkTestGet(options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkTestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<BookmarkRequest>} [bookmarkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarkApi
     */
    public v1BookmarkUpdateListPost(bookmarkRequest?: Array<BookmarkRequest>, options?: AxiosRequestConfig) {
        return BookmarkApiFp(this.configuration).v1BookmarkUpdateListPost(bookmarkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
