<template>
  <div class="tag-input-container">
    <div v-for="(tag, index) in tags" :key="index" class="tag">
      <span>{{ tag }}</span>
      <button class="remove-tag" @click="removeTag(index)">x</button>
    </div>
    <input
      ref="refInputValue"
      :placeholder="placeholder"
      class="tag-input"
      @keydown.enter.prevent="addTag"
      @keydown="handleKeydown"
      @keypress="handleKeypress"
      @blur.prevent="addTag" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ modelValue: string[]; placeholder: string }>()
const emit = defineEmits<{ (e: 'update:modelValue', value: string[]): void }>()

const tags = ref<string[]>([...props.modelValue])
const refInputValue = ref<HTMLInputElement>()

const isTimeDifferenceLessThan = (date1: Date, date2: Date, timeGap: number): boolean => {
  // 두 Date 객체의 밀리초 단위 값을 계산합니다.
  const timeDifference = Math.abs(date1.getTime() - date2.getTime())

  return timeDifference <= timeGap
}

const lastSpaceKeyTime = ref<Date>(new Date())
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === ',') {
    event.preventDefault()
    addTag()
  } else if (event.key === 'Backspace') {
    // NOTE: 맥북(맥os)에서 vdi로 엑세스하는 환경에서
    //  input text element에 한글 입력 도중에 space키를 입력하면, backspace keydown 이벤트가 발생하는 현상이 있어서,
    //  work around 로써 space 입력 직후(50ms이내)에 backspace가 입력되면 무시하도록 예외처리
    if (!isTimeDifferenceLessThan(lastSpaceKeyTime.value, new Date(), 50)) {
      if (refInputValue.value?.value === '' && tags.value.length > 0) {
        event.preventDefault()
        removeLastTag()
      }
    }
  }
}
const handleKeypress = (event: KeyboardEvent) => {
  if (event.key === ' ') {
    if (isTimeDifferenceLessThan(lastSpaceKeyTime.value, new Date(), 50)) {
      // NOTE: 맥북(맥os)에서 vdi로 엑세스하는 환경에서
      //  input text element에 한글 입력 도중에 space키를 입력하면,
      //  space -> backspace -> keydown 이벤트가 연달아 발생하면서 마지막 한글자가 input text에 추가되는 현상이 있어서,
      //  work around 로써 space 입력 직후(50ms이내)에 space가 입력된 경우,
      //  input text에 존재하는 글자가 마지막 tag의 마지막 글자와 동일한 경우 text를 삭제하도록 예외 처리
      if (refInputValue.value?.value && tags.value.length > 0) {
        const trimmedValue = refInputValue.value.value.trim()
        const lastTag = tags.value[tags.value.length - 1]
        const lastCharacter = lastTag.charAt(lastTag.length - 1)
        if (lastCharacter == trimmedValue) {
          refInputValue.value.value = ''
        }
      }
    } else {
      event.preventDefault()
      addTag()
    }
    lastSpaceKeyTime.value = new Date()
  }
}

const addTag = () => {
  if (refInputValue.value?.value) {
    const trimmedValue = refInputValue.value.value.trim()
    if (trimmedValue && !tags.value.includes(trimmedValue)) {
      tags.value.push(trimmedValue)
      emit('update:modelValue', tags.value)
    }

    refInputValue.value.value = ''
  }
}

const removeTag = (index: number) => {
  tags.value.splice(index, 1)
  emit('update:modelValue', tags.value)
}

const removeLastTag = () => {
  if (refInputValue.value?.value === '' && tags.value.length > 0) {
    tags.value.pop()
    emit('update:modelValue', tags.value)
  }
}
</script>

<style scoped>
.tag-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  padding: 5px;
  border-radius: 5px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #4b5563;
  color: #fff;
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.remove-tag {
  background: none;
  border: none;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}

.tag-input {
  border: none;
  outline: none;
  flex: 1;
}
</style>
