<template>
  <q-img
    :src="src"
    :no-spinner="noSpinner"
    spinner-color="gray"
    loading="lazy"
    @error="handleError"
    style="height: 100%; width: 100%">
    <template #error>
      <img :src="errorUrl" />
    </template>
  </q-img>
</template>

<script setup lang="ts">
export interface Props {
  src?: string
  noSpinner?: boolean
  errorIconSize?: 'S' | 'M' | 'L' | 'XL'
  errorCallback?: any | null
}

const props = withDefaults(defineProps<Props>(), {
  src: undefined,
  noSpinner: false,
  errorIconSize: 'S',
  errorCallback: null
})

const resourceUrl = import.meta.env.V_RESOURCE_BASE_PATH
const errorUrl = ref(`${resourceUrl}/images/common/default-people-30x30.png`)

onBeforeMount(() => {
  if (props.errorIconSize == 'M') {
    errorUrl.value = `${resourceUrl}/images/common/default-people-40x40.png`
  } else if (props.errorIconSize == 'L') {
    errorUrl.value = `${resourceUrl}/images/common/default-people-60x60.png`
  } else if (props.errorIconSize == 'XL') {
    errorUrl.value = `${resourceUrl}/images/common/default-people-100x120.png`
  }
})

const handleError = () => {
  if (props.errorCallback) {
    props.errorCallback(props.src)
  }
}
</script>
