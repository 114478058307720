import axios from 'axios'

export const useYellowpage = () => {
  const gwpOpenCenter = (sURL: string, sName: string, sFeatures: string) => {
    if (
      typeof sFeatures == 'string' &&
      sFeatures.length > 0 &&
      sFeatures.toLowerCase().indexOf('top') == -1 &&
      sFeatures.toLowerCase().indexOf('left') == -1
    ) {
      const features: string[] = sFeatures.split(/,/g)
      let width: string = '-1'
      let height: string = '-1'
      if (features.length && features.length > 0) {
        for (let idx = 0; idx < features.length; ++idx) {
          if (features[idx].toLowerCase().indexOf('width') != -1) {
            const w = features[idx].match(/\d+/g)
            if (w) width = w.toString()
          } else if (features[idx].toLowerCase().indexOf('height') != -1) {
            const h = features[idx].match(/\d+/g)
            if (h) height = h.toString()
          }
          if (width != '-1' && height != '-1') break
        }
      }

      // @ts-ignore
      const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left
      // @ts-ignore
      const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top

      const screenWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const screenHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const left = screenWidth / 2 - parseInt(width) / 2 + dualScreenLeft
      const top = screenHeight / 2 - parseInt(height) / 2 + dualScreenTop

      sFeatures += ',left=' + left + ',top=' + top
      try {
        const popup = window.open(sURL, sName, sFeatures)
        popup != null ? popup.focus() : null
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        const popup = window.open(sURL, sName, sFeatures)
        popup != null ? popup.focus() : null
      } catch (e) {
        console.log(e)
      }
    }
  }

  const openYellowPage = (k: string | undefined) => {
    //var yellowpageHost = siteMap?.value["SmartPortal.YellowPage"] != null ? siteMap?.value["SmartPortal.YellowPage"] : "/YellowPage";
    const yellowpageHost = '/YellowPage'
    const keyword = k
    const feature =
      'width=1020,height=618,directories=0,location=0,menubar=0,resizable=0,scrollbars=0,status=0,titlebar=0,toolbar=0'
    let address = ''

    if (keyword) {
      address = `${yellowpageHost}/search?category=ALL&keyword=${encodeURIComponent(keyword)}`
    } else {
      address = yellowpageHost
    }
    gwpOpenCenter(address, 'yellowpage', feature)
    sendYellowPageStatisticsLog()
  }

  const openYellowPageByUserId = (userId: string | undefined) => {
    //var yellowpageHost = siteMap?.value["SmartPortal.YellowPage"] != null ? siteMap?.value["SmartPortal.YellowPage"] : "/YellowPage";
    const yellowpageHost = '/YellowPage'
    const feature =
      'width=1020,height=618,directories=0,location=0,menubar=0,resizable=0,scrollbars=0,status=0,titlebar=0,toolbar=0'
    let address = ''

    if (userId) {
      address = `${yellowpageHost}/search/${userId}`
    } else {
      address = yellowpageHost
    }
    gwpOpenCenter(address, 'yellowpage', feature)
    sendYellowPageStatisticsLog()
  }

  /** YellowPage 오픈 시 통계 로그 전송 */
  const sendYellowPageStatisticsLog = async () => {
    await axios.get(`${import.meta.env.V_API_ORGCHART_PATH}/api/v1/Log/SetYellowPageLogQueue`)
  }

  return {
    gwpOpenCenter,
    openYellowPage,
    openYellowPageByUserId
  }
}
