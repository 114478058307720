<template>
  <teleport v-if="props.modelValue" to="#MikCommonPopupWarp">
    <div class="bg_light"></div>
    <div v-show="onLoadedIframe" class="mik_popup_instance">
      <div class="mik_shield" @mousewheel.prevent></div>
      <div class="pop_container">
        <div class="pop_header" style="cursor: pointer">
          <div class="left_area">
            <div class="pop_title mik_title">{{ popupTitle }}</div>
          </div>
          <div class="right_area">
            <q-icon name="close" size="md" color="white" @click="handleCancelClick" />
          </div>
        </div>
        <div class="pop_body mik_body">
          <iframe class="pop_iframe" :src="popupUrl" @load="handleLoadIframe"></iframe>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: boolean
  /** iframe url */
  popupUrl: string
  /** Option : title, width, height */
  opt?: Option | null
  /** 닫기 후 callback 함수 작성 필요 시 사용 */
  onCancel?: (() => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  opt: null,
  onCancel: null
})

export interface Option {
  title: string
  width: number
  height: number
}

const popupTitle = computed(() => {
  return props.opt?.title ?? 'Smart Portal'
})

const popupWidth = computed(() => {
  return props.opt?.width ?? 1000
})

const popupHeight = computed(() => {
  return props.opt?.height ?? 740
})

const onLoadedIframe = ref(false)
const headerHeight = ref(45)

const html = window.parent.document.querySelector('html')

/** popup size 수정 */
const adjustPopup = () => {
  // 컨텐츠 객체 확보
  const container = document.getElementsByClassName('pop_container')[0] as HTMLDivElement
  if (container) {
    const header = container.getElementsByClassName('pop_header')[0] as HTMLDivElement
    const body = container.getElementsByClassName('pop_body')[0] as HTMLDivElement

    // 사이즈 구하기
    const width = popupWidth.value
    const height = popupHeight.value

    // 사이즈 설정
    container.style.width = `${width}px`
    if (header) {
      container.style.height = `calc(${height + headerHeight.value}px)`
      body.style.height = `calc(100% - ${headerHeight.value}px)`
    } else {
      container.style.height = `${height}px`
      body.style.height = '100%'
    }

    // 좌표 구하기
    const margin = {
      height: Math.max(0, (window.innerHeight - headerHeight.value - height) / 2),
      width: Math.max(0, (window.innerWidth - width) / 2)
    }
    // 좌표 설정
    container.style.top = `${margin.height}px`
    container.style.left = `${margin.width}px`
  }
}

/** Parent Page Scroll 방지 */
const lockTopPageScroll = () => {
  const overflow = html?.style.overflow

  if (!overflow) {
    html?.setAttribute('style', 'overflow: hidden')
  }
}

/** iframe load */
const handleLoadIframe = () => {
  //setTimeout(() => {
  onLoadedIframe.value = true
  adjustPopup()
  lockTopPageScroll()
  //}, 100)
}

/** 닫기 */
const handleCancelClick = () => {
  if (props.onCancel) {
    props.onCancel()
  }
  onLoadedIframe.value = false
  html?.removeAttribute('style')
  emit('update:modelValue', false)
}

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

onBeforeMount(() => {
  // Teleport 영역 그리기
  if (!document.getElementById('MikCommonPopupWarp')) {
    const wrap = document.createElement('div')
    wrap.id = 'MikCommonPopupWarp'
    wrap.className = 'mik_pop_wrap'
    wrap.style.cssText = 'z-index:10000; position:fixed; top:0px; left:0px;'

    document.querySelector('body')?.appendChild(wrap)
  }
  window.addEventListener('resize', adjustPopup)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustPopup)
})
</script>

<style scoped lang="scss">
.mik_shield {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 0px;
  margin: 0px;
  background-color: rgb(0, 0, 0);
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.pop_container {
  margin: 0px;
  position: fixed;
  display: block;
}

.pop_body {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.pop_iframe {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.pop_header {
  display: table;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0 5px 0 20px;
  background-color: #272727;
}
.pop_header .left_area {
  display: table-cell;
  vertical-align: middle;
}
.pop_header .right_area {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
}
.pop_header .pop_title {
  font-size: 15px;
  line-height: 1.33em;
  color: #fff;
}

.pop_body {
  background-color: #fff;
}
</style>
