import axios, { AxiosError, type AxiosResponse } from 'axios'
import { UserAuthorityConstant } from '../api-autogen/portal'

interface ApiResult<T> {
  data?: T
  success?: boolean
  message?: string | null
  errorId?: string | null
  userAuthority?: UserAuthorityConstant
}

export const useAxiosMiddlewareWithErrorCallback = (errorCallback: Function | undefined) => {
  axios.interceptors.response.use(
    (response: AxiosResponse<ApiResult<any>>): Promise<AxiosResponse> => {
      // API 호출은 성공했지만, 서버에서 실패 flag를 전송하였을 경우
      if (response.status !== 200 || (response.data && response.data.success === false)) {
        if (errorCallback) {
          errorCallback(response.data)
        }
        return Promise.reject(response.data.message)
      }

      return Promise.resolve(response)
    },
    async (error: AxiosError | Error): Promise<AxiosError> => {
      if (axios.isAxiosError(error)) {
        // 401일 경우 로그인 페이지로 이동
        if (error.response?.status == 401) {
          const redirectUrl = `${location.href}`
          location.href = `${import.meta.env.V_AUTH_URL}?RedirectUrl=${redirectUrl}`
        }
      }
      return Promise.reject(error)
    }
  )
}

export const useAxiosMiddleware = () => {
  axios.interceptors.response.use(
    (response: AxiosResponse<ApiResult<any>>): Promise<AxiosResponse> => {
      // API 호출은 성공했지만, 서버에서 실패 flag를 전송하였을 경우
      if (response.status !== 200 || (response.data && response.data.success === false)) {
        return Promise.reject(response.data.message)
      }

      return Promise.resolve(response)
    },
    async (error: AxiosError | Error): Promise<AxiosError> => {
      if (axios.isAxiosError(error)) {
        // 401일 경우 로그인 페이지로 이동
        if (error.response?.status == 401) {
          const redirectUrl = `${location.href}`
          location.href = `${import.meta.env.V_AUTH_URL}?RedirectUrl=${redirectUrl}`
        }
      }
      return Promise.reject(error)
    }
  )
}
