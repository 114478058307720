<template>
  <div class="tooltip-container" @mousemove="handleMouseMove" @mouseleave="show = false">
    <slot></slot>
    <div v-if="show" class="tooltip-content" :style="{ top: mousePosition.y + 'px', left: mousePosition.x + 'px' }">
      <div class="tooltip-title">{{ props.modelValue?.messageTitle }}</div>
      <table class="tooltip-table">
        <tbody>
          <tr>
            <td class="bold">{{ t('NC_ItemList_Column_Memo') }}:</td>
            <td>{{ decodeHtml(props.modelValue?.messageMemo ?? '') }}</td>
          </tr>
          <tr>
            <td class="bold">{{ t('NC_ItemList_Column_Fired') }}:</td>
            <td>{{ props.modelValue?.currentFiredTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import { GetItemResult } from 'mik-common/src/api-autogen/notificationCenter/models/get-item-result'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  modelValue?: GetItemResult | null
}>()

const show = ref(false)
const mousePosition = reactive({ x: 0, y: 0 })

const handleMouseMove = (event: any) => {
  show.value = true
  mousePosition.x = event.clientX + 10
  mousePosition.y = event.clientY + 10
}

/** Html Decode */
const decodeHtml = (strHtml: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = strHtml
  return txt.value
}
</script>
<style scoped lang="scss">
.tooltip-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.tooltip-content {
  position: fixed;
  max-width: 500px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  white-space: normal;
  overflow-wrap: break-word;
}

.description {
  max-width: 470px;
  overflow-wrap: break-word;
}

.bold {
  font-weight: bold;
  min-width: 45px;
}

.tooltip-title {
  padding: 10px;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  font-weight: bold;
  background-color: rgb(5, 5, 68);
  color: #f9f9f9;
}

.tooltip-table {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}
</style>
