/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { BooleanApiResult } from '../models';
// @ts-ignore
import type { MyAppGroupModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { MyAppModel } from '../models';
// @ts-ignore
import type { MyAppModelIEnumerableApiResult } from '../models';
// @ts-ignore
import type { MyAppModelListApiResult } from '../models';
// @ts-ignore
import type { MyAppRequest } from '../models';
/**
 * MyAppApi - axios parameter creator
 * @export
 */
export const MyAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MyAppModel} [myAppModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappCommonListPost: async (myAppModel?: MyAppModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/myapp/common/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myAppModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/myapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappGroupListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/myapp/group/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappImagesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/myapp/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MyAppRequest>} [myAppRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappPost: async (myAppRequest?: Array<MyAppRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/myapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyAppApi - functional programming interface
 * @export
 */
export const MyAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MyAppModel} [myAppModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyappCommonListPost(myAppModel?: MyAppModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyAppModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyappCommonListPost(myAppModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyappGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyAppModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyappGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyappGroupListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyAppGroupModelIEnumerableApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyappGroupListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyappImagesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyAppModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyappImagesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<MyAppRequest>} [myAppRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MyappPost(myAppRequest?: Array<MyAppRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MyappPost(myAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyAppApi - factory interface
 * @export
 */
export const MyAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyAppApiFp(configuration)
    return {
        /**
         * 
         * @param {MyAppModel} [myAppModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappCommonListPost(myAppModel?: MyAppModel, options?: any): AxiosPromise<MyAppModelIEnumerableApiResult> {
            return localVarFp.v1MyappCommonListPost(myAppModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappGet(options?: any): AxiosPromise<MyAppModelIEnumerableApiResult> {
            return localVarFp.v1MyappGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappGroupListGet(options?: any): AxiosPromise<MyAppGroupModelIEnumerableApiResult> {
            return localVarFp.v1MyappGroupListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappImagesPost(options?: any): AxiosPromise<MyAppModelListApiResult> {
            return localVarFp.v1MyappImagesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MyAppRequest>} [myAppRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MyappPost(myAppRequest?: Array<MyAppRequest>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.v1MyappPost(myAppRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyAppApi - object-oriented interface
 * @export
 * @class MyAppApi
 * @extends {BaseAPI}
 */
export class MyAppApi extends BaseAPI {
    /**
     * 
     * @param {MyAppModel} [myAppModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAppApi
     */
    public v1MyappCommonListPost(myAppModel?: MyAppModel, options?: AxiosRequestConfig) {
        return MyAppApiFp(this.configuration).v1MyappCommonListPost(myAppModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAppApi
     */
    public v1MyappGet(options?: AxiosRequestConfig) {
        return MyAppApiFp(this.configuration).v1MyappGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAppApi
     */
    public v1MyappGroupListGet(options?: AxiosRequestConfig) {
        return MyAppApiFp(this.configuration).v1MyappGroupListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAppApi
     */
    public v1MyappImagesPost(options?: AxiosRequestConfig) {
        return MyAppApiFp(this.configuration).v1MyappImagesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MyAppRequest>} [myAppRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAppApi
     */
    public v1MyappPost(myAppRequest?: Array<MyAppRequest>, options?: AxiosRequestConfig) {
        return MyAppApiFp(this.configuration).v1MyappPost(myAppRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
