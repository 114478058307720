export type SubscriptionOption = {
  system: string
  content?: string
  callback?: Function
  callbackData?: any
  callbackType?: 'content' | 'system'
}

const SubscriptionHandlerObj = {
  isConnected: false
}

/** subscription 센터 연동 */
export const SubscriptionHandler = {
  /** 웹소켓 연결 및 콜백 등록 */
  subscribe: async (option: SubscriptionOption) => {
    //const system = ApvSubscriptionHandler.const.system
    // @ts-ignore
    if (!MikSubscriptionCenterManager) {
      console.debug('MikSubscriptionCenterManager not found')
      return
    }
    try {
      //const content = undefined
      // @ts-ignore
      await MikSubscriptionCenterManager.api.subscribe(option.system, option.content, {
        callback: {
          success: () => {
            console.debug('MikSubscriptionCenterManager 연결 완료')
            SubscriptionHandlerObj.isConnected = true
          },
          failed: () => {
            console.debug('MikSubscriptionCenterManager 연결 실패')
          }
        }
      })

      if (option.callbackType == 'system') {
        // @ts-ignore
        await MikSubscriptionCenterManager.event.addSubscriptionForSystem((data) => {
          try {
            console.debug('MikSubscriptionCenterManager received.', data)
            const receivedData = JSON.parse(data.data)
            if (option.callback) {
              option.callback(option, receivedData)
            }
          } catch (e) {
            console.log(e)
          }
        })
      } else {
        // @ts-ignore
        await MikSubscriptionCenterManager.event.addSubscriptionForContent((data) => {
          try {
            console.debug('MikSubscriptionCenterManager received.', data)
            const receivedData = JSON.parse(data.data)
            if (option.callback) {
              option.callback(option, receivedData)
            }
          } catch (e) {
            console.log(e)
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  /** 웹소켓 해제 */
  unSubscribe: async (option: SubscriptionOption) => {
    // @ts-ignore
    if (!MikSubscriptionCenterManager) {
      console.debug('MikSubscriptionCenterManager not found')
      return
    }
    try {
      // @ts-ignore
      await MikSubscriptionCenterManager.api.unregistChannel(option.system, option.content)
    } catch (e) {
      console.log(e)
    }
  }
}
