/* eslint camelcase: ["error", {allow: ["^g_","^d_","^om_"]}] */
/**
 * OrgChartDimmed.js
 * Dimmed 처리후 iframe 에서 조직도를 호출하기 위함
 * Copyright(c) Miksystem, Inc. (www.miksystem.com)
 */

import axios from 'axios'
import { Cookies } from 'quasar'

interface OrgChartOpenOption {
  callback: Function
  custom?: {
    height?: number
  }
  appType?: string
  returnType?: string
  selectCompany?: boolean
  searchAll?: boolean
  childSelectCompany?: boolean
  oneSelect?: boolean
  title?: any
  selectGroup?: boolean
  data?: any
  selectDeptCode?: string
  CompanyCodeForGroup?: string
  // useHris?: boolean
  targetDate?: string
  Modal?: boolean
  initUserID?: string
  searchRetiredUsers?: boolean
  additionalUrlParamText?: string
  maxReviewerCount?: number
  hasTopAreaTab?: boolean
  AllowApvOrgChartDuplicatedUserYN?: string
}

interface Argument {
  CallBack: Function
  CustomApp: {
    height?: number | undefined
  } | null
  SelectCompany: boolean
  SearchAll: boolean
  ChildSelectCompany: boolean
  OrgMapTitle: any
  ReturnType: string
  OnlyOneSelect: boolean
  AppType: string
  SelectGroup: boolean
  OrgMapData: any
  SelectDeptCode: string
  CompanyCodeForGroup: string
}

// OrgChart 의 경우 대문자 JSON 파일이 그대로 사용되기 때문에 별도의 interface 구현
export interface OrgModel {
  EntryType: number // 0:부서,1:그룹,2:사용자,3:아웃룩 연락처(그룹),4:아웃룩 연락처(사람)
  UserID: string
  EmpID: string
  CompanyCode: string
  CompanyName: string
  ChildCompanyCode: string
  ChildCompanyName: string
  DeptCode: string
  DeptName: string
  GroupCode: string
  GroupName: string
  UserName: string
  DisplayName: string
  EmailAddress: string
  SIPUri: string
  TitleCode: string
  TitleName: string
  RankCode: string
  RankName: string
  JobCode: string
  JobName: string
  DutyCode: string
  DutyName: string
  LocationCode: string
  LocationName: string
  EmpCode: string
  EmpName: string
  MobileTel: string
  OfficeTel: string
  OfficeTelExt: string
  JobDescription: string
}

//다국어 json
const multiLang = {
  ko: {
    CommonOrgChartErrMsg1: '조직도 호출 설정이 잘못되었습니다.',
    CommonOrgChartErrMsg2: 'Custom 조직도는 height를 지정해줘야 합니다.'
  },
  en: {
    CommonOrgChartErrMsg1: 'Organization chart call settings are incorrect.',
    CommonOrgChartErrMsg2: 'Custom organization charts require height to be specified.'
  },
  zh: {
    CommonOrgChartErrMsg1: 'Organization chart call settings are incorrect.',
    CommonOrgChartErrMsg2: 'Custom organization charts require height to be specified.'
  }
}
const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
//다국어 객체
const getMultiLang = () => {
  switch (langCode) {
    case 'ko':
      return multiLang.ko
    case 'en':
      return multiLang.en
    case 'zh':
      return multiLang.zh
  }

  return multiLang.ko
}
// const DOMAIN = import.meta.env.V_APP_ORG_DOMAIN;

/**
 * 조직도 오픈 (naming은 dimmed 이지만 window.open 함수 호출함)
 * @param config
 */

export const om_OpenOrgChartDimmed = (config: OrgChartOpenOption) => {
  return om_OpenOrgChart(config)
}

/**
 * 조직도 오픈 (window.open)
 * @param config
 */
export function om_OpenOrgChart(config: OrgChartOpenOption) {
  // OrgChart URL
  // const g_orgchartWindowOpen_url = `${DOMAIN}/OrgChart/orgmap.aspx?section=app&IsApv=true`; // 배포버전
  const g_orgchartWindowOpen_url = `/OrgChart/orgmap.aspx?section=app&IsApv=true` // 배포버전

  if (!config.callback) {
    alert(getMultiLang()['CommonOrgChartErrMsg1']) //'조직도 호출 설정이 잘못되었습니다.'
  } else {
    let d_height = 600
    let d_width = 1200

    let sURL = g_orgchartWindowOpen_url
    const vArguments: Argument = {
      CallBack: () => {},
      CustomApp: {
        height: 0
      },
      SelectCompany: false,
      SearchAll: false,
      ChildSelectCompany: false,
      OrgMapTitle: null,
      ReturnType: '',
      OnlyOneSelect: false,
      AppType: '',
      SelectGroup: false,
      OrgMapData: null,
      SelectDeptCode: '',
      CompanyCodeForGroup: ''
    }

    vArguments.CallBack = config.callback

    vArguments.CallBack = config.callback
    vArguments.CustomApp = config.custom || null

    let appType = config.appType || 'deptuser'
    appType = appType.toUpperCase()
    let returnType = config.returnType || 'json'
    returnType = returnType.toUpperCase()

    // 조직도 app 에서 회사 선택 여부.. winform에서 호출시 값 정해주지 않으면 기본 true로 처리하도록
    // var IsSelectCompany = true; // 기본값 false로 변경
    let IsSelectCompany = false

    // 2013-11-27 전사 유저 또는 부서 검색이 가능하도록 수정 searchAll == true이면 검색시 자동 전체 검색
    if (config.selectCompany != null) {
      IsSelectCompany = config.selectCompany === true
    }
    vArguments.SelectCompany = IsSelectCompany

    let IsSearchAll = false
    if (config.searchAll != null) {
      IsSearchAll = config.searchAll === true
    }
    vArguments.SearchAll = IsSearchAll

    let isChildSelectCompany = false
    if (IsSelectCompany === false && config.childSelectCompany != null) {
      isChildSelectCompany = config.childSelectCompany === true
    }
    vArguments.ChildSelectCompany = isChildSelectCompany

    const isOneSelect = config.oneSelect === true

    if (config.title) {
      vArguments.OrgMapTitle = config.title
    }
    vArguments.ReturnType = returnType

    if (appType === 'DEPTUSER') {
      if (isOneSelect) {
        d_width = d_width - 240
      }
    } else if (appType === 'USER') {
      if (isOneSelect) {
        d_width = d_width - 240
      }
    } else if (appType === 'DEPT') {
      if (isOneSelect === true) {
        if (isChildSelectCompany === true) {
          d_width = d_width - 240
        } else {
          d_width = d_width - 460
        }
      } else {
        d_width = d_width - 240
      }
    } else if (appType === 'GROUP') {
      d_width = d_width - 460
    }

    if (config.custom) {
      if (config.custom.height) {
        d_height = config.custom.height
      } else {
        alert(getMultiLang()['CommonOrgChartErrMsg2']) //'Custom 조직도는 height를 지정해줘야 합니다.'))
        return false
      }
    }
    // 스크린의 크기
    const sw = document.body.clientWidth / 2 - d_width / 2 // 브라우저 중앙위치
    const sh = screen.availHeight

    // 열 창의 포지션
    const px = sw + window.screenLeft // 현재브라우저 x위치
    const py = (sh - d_height) / 2

    const sFeatures = 'height=' + d_height + 'px,width=' + d_width + 'px,resizable=yes' + ',left=' + px + ',top=' + py

    vArguments.OnlyOneSelect = isOneSelect
    vArguments.AppType = appType

    sURL += '&AppType=' + appType

    vArguments.SelectGroup = config.selectGroup === true

    let data = null
    if (!isOneSelect) {
      data = config.data
    }
    vArguments.OrgMapData = data

    let selectDeptCode = ''
    selectDeptCode =
      typeof config.selectDeptCode === 'string' && config.selectDeptCode.length > 0 ? config.selectDeptCode : ''
    vArguments.SelectDeptCode = selectDeptCode
    if (selectDeptCode !== '') {
      sURL += '&SelectDeptCode=' + selectDeptCode
    }

    let CompanyCodeForGroup = ''
    CompanyCodeForGroup =
      typeof config.CompanyCodeForGroup === 'string' && config.CompanyCodeForGroup.length > 0
        ? config.CompanyCodeForGroup
        : ''
    vArguments.CompanyCodeForGroup = CompanyCodeForGroup
    if (selectDeptCode !== '') {
      sURL += '&CompanyCodeForGroup=' + CompanyCodeForGroup
    }

    // 하드코딩
    //  if ($.cookie) {
    //     var lc = $.cookie('GWP_LANGUAGE_CODE');
    //     if (lc === 'zh') lc = 'cn';
    //     sURL += '&langCode=' + lc;
    //  }
    //  else if (g_LangCode) {
    //     sURL += "&langCode=" + g_LangCode;
    //  }

    sURL += '&langCode=' + (Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko')
    // try { sURL += "&ACCOUNT=" + g_OrgChart_UserID; } catch (e) { }

    /// / HRIS 대응
    // if (config.useHris == true && config.targetDate) {
    //    sURL += "&useHris=TRUE&targetDate=" + config.targetDate;
    // }

    // if (config.useHris == true && g_CurrentUser && g_CurrentUser.UserID) {
    //    sURL += "&initUserID=" + g_CurrentUser.UserID;
    // }

    let initUserID = ''
    initUserID = typeof config.initUserID === 'string' && config.initUserID.length > 0 ? config.initUserID : ''

    if (initUserID !== '') {
      sURL += '&initUserID=' + initUserID
    }

    if (config.searchRetiredUsers) {
      sURL += '&retiredUsers=Y'
    }

    const date = new Date()
    sURL += '&_dc=' + date.getTime()
    config.Modal = config.Modal || false

    // @ts-ignore
    window.g_orgLoadConfig = vArguments
    window.open(sURL, 'orgChartPopup', sFeatures)
    sendStatisticsLog()
  }
}

type UncapitalizeObjectKeys<T extends object> = {
  [key in UncapitalizeKeys<T>]: Capitalize<key> extends keyof T ? T[Capitalize<key>] : never
}

type UncapitalizeKeys<T extends object> = Uncapitalize<keyof T & string>

export const upperCaseKeys = <T extends object>(obj: T): UncapitalizeObjectKeys<T> => {
  const entries = Object.entries(obj)
  const mappedEntries = entries.map(([k, v]) => [`${k.substring(0, 1).toUpperCase()}${k.substring(1)}`, v])

  return Object.fromEntries(mappedEntries) as UncapitalizeObjectKeys<T>
}

export const lowerCaseKeys = <T extends object>(obj: T): UncapitalizeObjectKeys<T> => {
  const entries = Object.entries(obj)
  const mappedEntries = entries.map(([k, v]) => [`${k.substring(0, 1).toLowerCase()}${k.substring(1)}`, v])

  return Object.fromEntries(mappedEntries) as UncapitalizeObjectKeys<T>
}

/** 조직도 오픈 시 통계 로그 전송 */
const sendStatisticsLog = async () => {
  await axios.get(`${import.meta.env.V_API_ORGCHART_PATH}/api/v1/Log/SetOrgChartLogQueue`)
}

// let popupWindow: any = null

// export function om_OpenGroupSelecter (config: OrgChartOpenOption) {
//   const dHeight = 540
//   const dWidth = 720

//   const sw = document.body.clientWidth / 2 - dWidth / 2 // 브라우저 중앙위치

//   const sh = screen.availHeight

//   // 열 창의 포지션
//   //        const px = (sw - d_width) / 2;
//   const px = sw + window.screenLeft // 현재브라우저 x위치
//   const py = (sh - dHeight) / 2
//   // @ts-ignore
//   window.g_orgPositionData = config.data
//   // @ts-ignore
//   window.callback = config.callback

//   let sURL = `/smartTalk/ApvOrgChart/Pages/GroupSelector.aspx?Callback=${config.callback.name}`
//   if (config.additionalUrlParamText) {
//     sURL += config.additionalUrlParamText
//   }
//   const sFeatures = `height=${dHeight}px,width=${dWidth}px,resizable=yes,left=${px},top=${py}`

//   if (popupWindow != null && !popupWindow.closed) {
//     popupWindow.focus()
//     return
//   }
//   popupWindow = window.open(sURL, '_blank', sFeatures)
// }
