import { type SubscriptionOption, SubscriptionHandler } from 'mik-common/src/utils/subscription-handler'
import { debounce } from 'quasar'
import { Notify } from 'quasar'
import i18n from 'mik-common/src/lang/lang'

/** 포탈 global subscription용 시스템 코드 */
const subscriptionSystemCode = 'SmartPortalGlobal'

/** 포탈 global - subscription 센터 연동 (export 하지 않을 내부 로직 전용) */
const PortalGlobalSubscriptionHandlerInternal = {
  messageCodeConst: {
    /** 버전업으로 인한 새로고침 요청 */
    VERSION_UP_REFRESHING_REQUIRED: 'VERSION_UP_REFRESHING_REQUIRED'
  },
  subscribedOptions: [] as SubscriptionOption[],
  callbackWrapper: debounce((option: SubscriptionOption, data: any) => {
    console.debug('PortalGlobalSubscriptionHandlerInternal callbackWrapper', option, data)
    if (data.MessageCode == PortalGlobalSubscriptionHandlerInternal.messageCodeConst.VERSION_UP_REFRESHING_REQUIRED) {
      PortalGlobalSubscriptionHandlerInternal.showVersionUpRefreshingRequiredMessage()
    }
  }, 5000),
  /** subscription 고유키 생성 */
  generateSubscriptionContentKey: () => {
    // 서버사이드의 push 시의 content 코드와 동일해야 함
    return 'PortalGlobalSubscription'
  },
  /** 버전업 알림 */
  showVersionUpRefreshingRequiredMessage: () => {
    const message = i18n.global.t(
      'PortalGlobalSubsciptionVersionUpRefreshingRequiredMessage',
      '최신 버전을 적용하기 위해 화면을 새로고침 하십시오.'
    )
    const labelNameRefreshBtn = i18n.global.t(
      'PortalGlobalSubsciptionVersionUpRefreshingRequiredButtonName',
      '새로고침'
    )
    Notify.create({
      type: 'positive',
      icon: 'refresh',
      message: message,
      caption: undefined,
      position: 'top',
      classes: 'toast_gnb',
      timeout: 0,
      actions: [
        {
          label: labelNameRefreshBtn,
          color: 'yellow',
          handler: () => {
            const url = location.href
            location.href = url
          }
        }
      ]
    })
  }
}

/** 포탈 global - subscription 센터 연동 */
export const PortalGlobalSubscriptionHandler = {
  /** 웹소켓 연결 및 콜백 등록 */
  subscribe: async () => {
    console.debug('PortalGlobalSubscriptionHandler subscribe')
    try {
      const content = PortalGlobalSubscriptionHandlerInternal.generateSubscriptionContentKey()
      const internalOption: SubscriptionOption = {
        system: subscriptionSystemCode,
        content: content,
        callback: PortalGlobalSubscriptionHandlerInternal.callbackWrapper
      }
      SubscriptionHandler.subscribe(internalOption)
      PortalGlobalSubscriptionHandlerInternal.subscribedOptions.push(internalOption)
    } catch (e) {
      console.log(e)
    }
  },
  /** 기존 게시판 subscription 해제 */
  clear: async () => {
    console.debug('PortalGlobalSubscriptionHandler clear')
    if (PortalGlobalSubscriptionHandlerInternal.subscribedOptions.length == 0) {
      return
    }

    PortalGlobalSubscriptionHandlerInternal.subscribedOptions.forEach(async (v) => {
      await PortalGlobalSubscriptionHandler.unSubscribe()
    })
    PortalGlobalSubscriptionHandlerInternal.subscribedOptions.splice(
      0,
      PortalGlobalSubscriptionHandlerInternal.subscribedOptions.length
    )
  },
  /** 웹소켓 해제 */
  unSubscribe: async () => {
    console.debug('PortalGlobalSubscriptionHandler unsubscribe')
    try {
      const content = PortalGlobalSubscriptionHandlerInternal.generateSubscriptionContentKey()
      const internalOption: SubscriptionOption = {
        system: subscriptionSystemCode,
        content: content
      }
      // @ts-ignore
      await SubscriptionHandler.unSubscribe(internalOption)
    } catch (e) {
      console.log(e)
    }
  }
}
