<template>
  <div class="error_container">
    <div class="top">
      <div :class="iconType" class="ico_alert_all"></div>
    </div>
    <div class="bottom">
      <div class="message main">{{ messageMain }}</div>
      <div class="message sub">{{ messageSub }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const messageMain = ref('')
const messageSub = ref('')
const { t } = useI18n()
const route = useRoute()

const iconType = reactive<string[]>([])

onBeforeMount(() => {
  const code = route.params.code

  if (code == '204') {
    messageMain.value = t('FailedNoContent')
    iconType.push('ico_alert_all_error')
  } else if (code == '401') {
    messageMain.value = t('FailedAccessdenied')
    iconType.push('ico_alert_all_accessdenied')
  } else if (code == '404') {
    messageMain.value = t('FailedNotfound')
    iconType.push('ico_alert_all_notfound')
  } else if (code == '500') {
    messageMain.value = t('FailedUnknown')
    iconType.push('ico_alert_all_error')
  } else {
    messageMain.value = t('FailedUnknown')
    iconType.push('ico_alert_all_error')
  }
})
</script>

<style scoped>
.error_container {
  min-width: 300px;
  max-width: 900px;
  width: 80%;
  margin: 100px auto;
  padding: 0px;
  text-align: center;
}

.error_container .top {
  margin: 20px auto;
}

.error_container .bottom {
  margin: 10px auto;
}

.error_container .bottom .message {
  margin: 10px 0px;
}

.error_container .bottom .main {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.error_container .bottom .sub {
  font-size: 1em;
  font-weight: normal;
  color: #aaa;
}

.ico_alert_all {
  display: inline-block;
  background: url(/PortalResources/images/popup/img_alert.png) no-repeat;
  vertical-align: middle;
}

.ico_alert_all_accessdenied {
  width: 80px;
  height: 80px;
  background-position: -100px top;
}

.ico_alert_all_error {
  width: 80px;
  height: 80px;
  background-position: -400px top;
}

.ico_alert_all_notfound {
  width: 142px;
  height: 56px;
  background-position: -600px top;
}
</style>
