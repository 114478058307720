/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.GlobalMenu.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ApiResult } from '../models';
// @ts-ignore
import type { CompanyModelListApiResult } from '../models';
// @ts-ignore
import type { MyTenantEntityListApiResult } from '../models';
// @ts-ignore
import type { PersonalizationManagementEntityApiResult } from '../models';
// @ts-ignore
import type { StringApiResult } from '../models';
// @ts-ignore
import type { UserInfoModelApiResult } from '../models';
// @ts-ignore
import type { UserProfileModelApiResult } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 회사 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserCompanyListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/CompanyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserGetLangCodeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/getLangCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사용자 정보 반환
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserInfoGet: async (langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 테넌트 목록 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserMyTenantsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/myTenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 개인화 정보 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPersonalizationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/personalization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 변경
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSetLangCodeGet: async (langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/setLangCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 프로필 카드 데이터 조회
         * @param {string} [userId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUserProfileCardGet: async (userId?: string, langCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/UserProfileCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 회사 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserCompanyListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserCompanyListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserGetLangCodeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserGetLangCodeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사용자 정보 반환
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserInfoGet(langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserInfoGet(langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 테넌트 목록 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserMyTenantsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyTenantEntityListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserMyTenantsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 개인화 정보 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserPersonalizationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalizationManagementEntityApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserPersonalizationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 변경
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserSetLangCodeGet(langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserSetLangCodeGet(langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 프로필 카드 데이터 조회
         * @param {string} [userId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserUserProfileCardGet(userId?: string, langCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UserUserProfileCardGet(userId, langCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary 회사 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserCompanyListGet(options?: any): AxiosPromise<CompanyModelListApiResult> {
            return localVarFp.v1UserCompanyListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserGetLangCodeGet(options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.v1UserGetLangCodeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사용자 정보 반환
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserInfoGet(langCode?: string, options?: any): AxiosPromise<UserInfoModelApiResult> {
            return localVarFp.v1UserInfoGet(langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 테넌트 목록 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserMyTenantsGet(options?: any): AxiosPromise<MyTenantEntityListApiResult> {
            return localVarFp.v1UserMyTenantsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 개인화 정보 반환
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPersonalizationGet(options?: any): AxiosPromise<PersonalizationManagementEntityApiResult> {
            return localVarFp.v1UserPersonalizationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 현재 사용자 다국어 언어 변경
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserSetLangCodeGet(langCode?: string, options?: any): AxiosPromise<ApiResult> {
            return localVarFp.v1UserSetLangCodeGet(langCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 프로필 카드 데이터 조회
         * @param {string} [userId] 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserUserProfileCardGet(userId?: string, langCode?: string, options?: any): AxiosPromise<UserProfileModelApiResult> {
            return localVarFp.v1UserUserProfileCardGet(userId, langCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary 회사 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserCompanyListGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserCompanyListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 현재 사용자 다국어 언어 가져오기
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserGetLangCodeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserGetLangCodeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사용자 정보 반환
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserInfoGet(langCode?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserInfoGet(langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 테넌트 목록 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserMyTenantsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserMyTenantsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 개인화 정보 반환
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserPersonalizationGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserPersonalizationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 현재 사용자 다국어 언어 변경
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserSetLangCodeGet(langCode?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserSetLangCodeGet(langCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 프로필 카드 데이터 조회
     * @param {string} [userId] 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1UserUserProfileCardGet(userId?: string, langCode?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).v1UserUserProfileCardGet(userId, langCode, options).then((request) => request(this.axios, this.basePath));
    }
}
