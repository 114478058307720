<template>
  <common-popup v-model="showBoardPopup" size="xl" style="z-index: 2000" class="tag_popup">
    <div class="sect_content">
      <div class="col-9 row justify-end">
        <mik-search-input
          v-model="keyword"
          dense
          :search-click="handleSearch"
          :placeholder="$t('ArticleTagPlaceholder')" />
      </div>
      <div class="board_list">
        <table class="board_list_table">
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 200px" />
            <col style="width: 160px" />
            <col style="width: 80px" />
          </colgroup>
          <thead>
            <tr class="board_list_row">
              <th class="board_list_head">
                {{ $t('Title') }}
              </th>
              <th class="board_list_head">
                {{ $t('Summary') }}
              </th>
              <th class="board_list_head">
                {{ $t('ArticleTag') }}
              </th>
              <th class="board_list_head">
                {{ $t('DraftDate') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <template v-for="(item, index) of list" :key="item.logId">
                <tr class="board_list_row" style="cursor: pointer" @click="handleMovePage(item)">
                  <th class="board_list_data">
                    <span class="data_ellipsis ellipsis_wrapper">
                      {{ item.title }}
                    </span>
                  </th>
                  <th class="board_list_data">
                    <span class="data_ellipsis ellipsis_wrapper">
                      {{ item.summary }}
                    </span>
                  </th>
                  <th class="board_list_data">
                    <span class="data_ellipsis ellipsis_wrapper">
                      {{ item.tagContents }}
                    </span>
                  </th>
                  <th class="board_list_data">
                    <span>
                      {{ moment(item.createdDate).format('YYYY-MM-DD') }}
                    </span>
                  </th>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr class="board_list_row_nodata">
                <td class="board_list_data" :colspan="4">
                  <div class="nodata">
                    <div class="nodata_wrap">
                      <div class="ico_board ico_board_info"></div>
                      <div class="none_txt none_txt_font_size">{{ $t('BoardNoPost') }}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <mik-pagination
        v-if="list.length > 0"
        :current-page="pageNumber"
        :page-size="pageSize"
        :total-item-count="list[0].totalCount"
        @move="handleSearch">
      </mik-pagination>
    </div>
  </common-popup>
</template>

<script setup lang="ts">
import CommonPopup from 'mik-common/src/components/popup/CommonPopup.vue'
import MikSearchInput from 'mik-common/src/components/common/MikSearchInput.vue'
import MikPagination from 'mik-common/src/components/layout/MikPagination.vue'
import { TagSearchApi, SearchTagRequestModel, TagItemEntity } from 'mik-common/src/api-autogen/taggingcenter'
import moment from 'moment'

interface Props {
  modelValue: boolean
  systemType: string
  itemGroupId: string | null | undefined
  tag: string
  pageSize: number
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  systemType: '',
  itemGroupId: null,
  tag: '',
  pageSize: 10
})

const router = useRouter()
const api = new TagSearchApi()

const list = ref<TagItemEntity[]>([])

const keyword = ref<string>('')
const pageNumber = ref<number>()

const showBoardPopup = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const handleSearch = () => {
  const request: SearchTagRequestModel = {
    systemType: props.systemType,
    itemGroupId: props.itemGroupId,
    keyword: keyword.value,
    extensionKey: '',
    pageNum: pageNumber.value,
    pageSize: props.pageSize
  }

  api.v1TagsearchSearchPost(request).then((responses) => {
    if (responses.data.success) {
      list.value = responses.data.data?.tagItemList ?? []
    }
  })
}

const handleMovePage = async (item: TagItemEntity) => {
  const result = await router.push({
    path: item.referenceUrl as string,
    force: true
  })
  if (!result) {
    showBoardPopup.value = false
  }
}

onBeforeUpdate(() => {
  if (showBoardPopup.value) {
    keyword.value = props.tag
    pageNumber.value = 1

    handleSearch()
  }
})

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
</script>
