<template>
  <div id="lay_footer">
    <div class="footer_wrap">
      <div class="logo_footer">
        <img :src="`${BASE_PATH}/images/common/logo_footer.png`" />
      </div>
      <div class="copyright">
        Copyright©2023
        <a style="cursor: pointer; color: #0094ff; text-decoration: underline" @click.prevent="clickMiksystem">
          miksystem Inc.
        </a>
        , All rights are reserved
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const BASE_PATH = import.meta.env.V_RESOURCE_BASE_PATH

const clickMiksystem = () => {
  location.href = 'https://www.miksystem.com'
}
</script>
